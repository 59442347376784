import React, { useState } from 'react'
import { RiArrowRightDoubleFill } from "react-icons/ri";
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';

export default function FileShevah(props) {
    const [imageUrl, setImageUrl] = useState('');
    const [loading, setLoad] = useState(false);
    const storage = getStorage();

    const handleImageChange = (e) => {
        setLoad(true);
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            // reader.onloadend = () => {
            //     setImageLocal(reader.result)
            // };
            reader.readAsDataURL(file); // Read the file as a Data URL
        }
        if (e.target.files[0]) {
            //setImage(e.target.files[0]);
            // if (ref(storage, `images/${e.target.files[0].name}`)) {
            // }

            const storageRef = ref(storage, `images/${e.target.files[0].name}`);
            uploadBytes(storageRef, e.target.files[0]).then((snapshot) => {
                getDownloadURL(snapshot.ref).then((downloadURL) => {
                    setImageUrl(downloadURL);
                    setLoad(false);
                });
            }).catch((error) => {
                console.error('Error uploading image: ', error);
                setLoad(false);
            }).catch((error) => {
                console.error('Error uploading image:2 ', error);
                setLoad(false);
            });
        }

    };

    const nextPage = (imageUrl) => {
        props.finishShevah(imageUrl);
    }

    return (
        <div>
            <div id="" className="content">
                <h5 className=' text-dark'>העלה טופס שומת שבח</h5>
                <h6 className=' bg-white text-danger'>* טופס זה הוא חובה</h6>
                <div>
                    <div className=''>
                        <input type="file" accept="image/*" id="someInput" onChange={handleImageChange} />
                        <label htmlFor="someInput" className="custom-file-input mb-4 mt-2">העלה שבח</label>
                    </div>
                </div>
                {loading &&
                    <div className="charging-circle-container">
                        <div className="charging-circle"></div>
                    </div>
                }
                {
                    (imageUrl != '') &&
                  <a className='text-black' href={imageUrl} target="_blank" >לצפייה בטופס</a>
                }
                <div className='d-flex justify-content-between' style={{ direction: 'rtl' }}>
                    <button onClick={() => props.bakePage(2)} style={{ fontSize: '18px' }} className="btnNext pe-3 ps-3 mt-3 border-3"> <RiArrowRightDoubleFill size={30} />
                    </button>
                    {
                        (imageUrl != '') &&
                        <button onClick={() => nextPage(imageUrl)} style={{ fontSize: '18px' }} className="btnNext pe-3 ps-3   anBack  mt-3 border-3"> המשך </button>
                    }

                </div>
            </div>

        </div>
    )
}
