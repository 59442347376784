import React, { useEffect, useState } from 'react'
import '../lid.css'
import { getAuth, createUserWithEmailAndPassword, fetchSignInMethodsForEmail, signInWithEmailAndPassword, deleteUser, signOut } from "firebase/auth";
import { getDatabase, ref, set, remove, child, get, update } from "firebase/database";
import '../loading.css';
import ItemManger from './itemManger';
import { uid, useUID } from 'react-uid';
import { useParams } from 'react-router-dom';
import { IoIosPeople } from "react-icons/io";
import ItemBack from '../backNumber/itemBack';


export default function AddCus() {
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [password, setPassword] = useState('');
    const [nameUser, setName] = useState('');
    const [loading, setLoad] = useState(false);
    const [mess, setMess] = useState(false);
    const [messErr, setMessErr] = useState("");
    const [linkWeb, setlinkWeb] = useState(false);
    const [messCompliteData, setMessData] = useState(false);
    const [arrayDataMager, setManger] = useState([]);
    //const admin = require('firebase-admin');
    const { name } = useParams()


    useEffect(() => {
        const storedArray = JSON.parse(localStorage.getItem('myArrayMenger')) || [];
        setManger(storedArray)
    }, []);

    const removeItem = async (nameCurrentUser, email, password, phone) => {
        const auth = getAuth()
        signOut(auth).then(() => {
            signInWithEmailAndPassword(auth, email, password)
                .then((userCredential) => {
                    const user = userCredential.user;
                    auth.currentUser.delete()
                    signInWithEmailAndPassword(auth, localStorage.getItem(`emailLogin${name}`), localStorage.getItem(`passwordLogin${name}`))
                        .then((userCredential) => {
                            const user = userCredential.user;
                            console.log("New user signed in2222: ", auth.currentUser);
                        })
                        .catch((error) => {
                            const errorCode = error.code;
                            const errorMessage = error.message;
                            console.error("Error signing in: ", errorCode, errorMessage);
                        });
                })
                .catch((error) => {
                    const errorCode = error.code;
                    const errorMessage = error.message;
                    console.error("Error signing in: ", errorCode, errorMessage);
                });
        }).catch((error) => {
            console.error("Error signing out: ", error);
        });
        const db = getDatabase();
        remove(ref(db, 'ron_mas/' + nameCurrentUser));
        remove(ref(db, 'ron_mas/phoneUser/' + phone));
        let tempArry = []
        tempArry = arrayDataMager.filter((item) => item.name !== nameCurrentUser);
        setManger(tempArry);
        localStorage.setItem('myArrayMenger', JSON.stringify(tempArry))
    }

    const handleLogin = async (e) => {
        const db = getDatabase();
        const dbRef = ref(getDatabase());

        const myListData = [];
        e.preventDefault();
        if (nameUser !== '' && phone !== '' && password !== '' && email !== '') {
            setLoad(true);
            get(child(dbRef, `ron_mas/phoneUser`)).then((snapshot) => {
                if (snapshot.hasChild(phone)) {
                    setLoad(false);
                    setMessErr("המספר קיים במערכת *");
                    setMessData(true)
                    setTimeout(() => {
                        setMessData(false)
                    }, 3000);
                } else {
                    get(child(dbRef, `ron_mas/`)).then((snapshot) => {
                        if (snapshot.hasChild(nameUser)) {
                            setLoad(false);
                            setMessErr("השם קיים במערכת *");
                            setMessData(true)
                            setTimeout(() => {
                                setMessData(false)
                            }, 3000);
                        } else {
                            console.log("No data available");
                            setLoad(true);
                            const auth = getAuth();
                            fetchSignInMethodsForEmail(auth, email)
                                .then((signInMethods) => {
                                    // If signInMethods is not empty, it means the email already exists
                                    if (signInMethods.length > 0) {
                                        setMessErr("אימייל קיים במערכת * ");
                                        setMessData(true)
                                        setLoad(false);
                                        setTimeout(() => {
                                            setMessData(false)
                                        }, 3000);
                                        // Handle the case where the email is already in use
                                        console.log("Email already exists.");
                                    } else {
                                        // If signInMethods is empty, proceed to create the user
                                        createUserWithEmailAndPassword(auth, email, password)
                                            .then((userCredential) => {
                                                const user = userCredential.user;
                                                // Get the UID
                                                const uid = user.uid;
                                                set(ref(db, 'ron_mas/' + nameUser + "/details/"), {
                                                    "email": email,
                                                    "password": password,
                                                    "name": nameUser,
                                                    "phone": phone,
                                                    "uid": uid,
                                                    "timeService": 0,
                                                    "dateStartService": 0,
                                                    "link": "https://touchpay.co.il/" + nameUser,

                                                }).then(() => {
                                                    update(ref(db, 'ron_mas/' + nameUser), {
                                                        "userWork": "on"
                                                    })
                                                    set(ref(db, 'ron_mas/phoneUser/' + phone), {
                                                        "user": phone
                                                    })
                                                    setLoad(false);
                                                    setMess(true);
                                                    setlinkWeb(true);
                                                    setTimeout(() => {
                                                        setMess(false);
                                                    }, 3000);
                                                });
                                                myListData.push(
                                                    {
                                                        name: nameUser,
                                                        phone: phone,
                                                        email: email,
                                                        password: password,
                                                        link: "https://touchpay.co.il/" + nameUser,
                                                    }
                                                )
                                                const tamp = [...arrayDataMager, ...myListData];
                                                localStorage.setItem('myArrayMenger', JSON.stringify(tamp));
                                                setManger(tamp);
                                                setEmail('');
                                                setPassword('');
                                                setPhone('');
                                                setTimeout(() => {
                                                    setlinkWeb(false)
                                                    setName('');
                                                }, 10000);
                                                // User created successfully
                                                console.log("User created successfully.");
                                            })
                                            .catch((error) => {
                                                if (error.message === "Firebase: Password should be at least 6 characters (auth/weak-password).") {
                                                    setMessErr("לפחות 6 תווים בסיסמה * ");
                                                    setMessData(true)
                                                    setLoad(false);
                                                    setTimeout(() => {
                                                        setMessData(false)
                                                    }, 3000);
                                                } else {
                                                    setMessErr("אימייל קיים במערכת * ");
                                                    setMessData(true)
                                                    setLoad(false);
                                                    setTimeout(() => {
                                                        setMessData(false)
                                                    }, 3000);
                                                }
                                                // Handle any errors during user creation
                                                console.error("Error creating user:", error.message);
                                            });
                                    }
                                })
                                .catch((error) => {
                                    // Handle any errors during email checking
                                    console.error("Error checking email:", error.message);
                                });
                        }
                    }).catch((error) => {
                        console.error(error);
                    });


                }
            });

        } else {
            setMessErr("חסר פרטים  *");
            setMessData(true)
            setTimeout(() => {
                setMessData(false)
            }, 3000);

        }

    };


    const updateDataManger = (name, selectedOption, selectedDate, stopSite) => {
        // eslint-disable-next-line
        arrayDataMager.map((item, index) => {
            if (item.name === name) {
                arrayDataMager[index].timeService = selectedOption;
                arrayDataMager[index].dateStartService = selectedDate;
                arrayDataMager[index].userWork = stopSite;
                localStorage.setItem('myArrayMenger', JSON.stringify(arrayDataMager));
            }
        })

    }




    return (
        <div>
            <div className='container d-flex justify-content-center align-items-center ' style={{ direction: 'rtl' }}>
                <div className=" col-11 col-xl-5 mt-xl-4 broderLog p-3 mt-4 backcolorLoging ">
                    <h3 className='textLog text-dark'>הרשמה למערכת</h3>
                    <form onSubmit={handleLogin}>
                        <div className="form-group mb-2">
                            <input
                                type="name"
                                className="form-control"
                                placeholder="שם באנגלית"
                                value={nameUser}
                                onChange={(e) => setName(e.target.value)}
                            />
                        </div>
                        <div className="form-group mb-2">
                            <input
                                type="number"
                                className="form-control"
                                placeholder="מספר נייד"
                                value={phone}
                                onChange={(e) => setPhone(e.target.value)}
                            />
                        </div>
                        <div className="form-group">
                            <input
                                type="email"
                                className="form-control"
                                placeholder="כתובת אימייל"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </div>
                        <div className="form-group mt-2">
                            <input
                                type="password"
                                className="form-control"
                                placeholder="סיסמה"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </div>
                        <button type="submit" className="btnCatgory  text-white p-2  mt-3 border-3  ps-5 pe-5"   >הרשמה</button>
                    </form>
                    {loading &&
                        <div className="charging-circle-container">
                            <div className="charging-circle"></div>
                        </div>
                    }
                    {mess &&
                        <div className=' d-flex justify-content-center'>
                            <h4 style={{ direction: 'ltr' }} className=' text-dark mt-3'>! נרשם בהצלחה</h4>
                        </div>
                    }
                    {
                        linkWeb && <div className='mt-3 text-center'>
                            <a style={{ textDecoration: "none" }} target="_blank" rel="noreferrer" href={`https://touchpay.co.il/${nameUser}`} className=' text-primary'>
                                https://touchpay.co.il/{nameUser}
                            </a>
                        </div>

                    }
                    {messCompliteData &&
                        <div className=' d-flex justify-content-center'>
                            <h4 style={{ direction: 'ltr' }} className=' text-danger mt-3'>{messErr}</h4>
                        </div>
                    }
                </div>
            </div>
            <div className=' container d-flex justify-content-center align-items-center ' style={{ direction: 'rtl' }}>
                <div className=" col-11 col-xl-5 mt-xl-4 broderLog p-3 mt-4 backcolorLoging ">
                    <h3 className='textLog text-dark'>  רואי חשבון הרשומים במערכת</h3>
                    {
                        arrayDataMager.map((item, i) => (
                            <div className=' mt-4' key={i}>
                                {item && <ItemManger updateDataManger={updateDataManger} removeItem={removeItem} item={item}></ItemManger>}
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>


    )
}
