import React, { useEffect, useState } from 'react'
import { getDatabase, ref, get, child, onChildAdded, onChildRemoved } from 'firebase/database';
import ItemBack from './itemBack';
import { IoIosPeople } from "react-icons/io";
import { useParams } from 'react-router-dom';
import _ from 'lodash';


export default function CallBack() {
    const [arrayDataBack, setBack] = useState([]);
    const [arrayDataBtowB, setBtowB] = useState([]);

    const [loading, setloading] = useState(true);
    let { name } = useParams();
    let [styleButtonBtc, setColorC] = useState("white")
    let [styleButtonBtB, setColorB] = useState("green")



    useEffect(() => {
        if (name == "ron" || name == "touchpay") {
            getListBtB();
        } else {
            getList();
        }
        lisnDBBack();
        lisnDBBackB2b();
    }, []);

    const lisnDBBack = () => {
        const dbRef = ref(getDatabase());
        const itemsRef = child(dbRef, `/ron_mas/${name}/back/`);
        const storedItems = JSON.parse(localStorage.getItem(`myArrayBack${name}`)) || [];
        const handleChildAdded = (snapshot) => {
            const newItem = snapshot.val();
            const itemId = snapshot.key;

            // Check if the item already exists in the list
            const isItemExist = storedItems.some(item => item.idKay === itemId);
            if (!isItemExist) {
                const updatedItems = [
                    {
                        name: newItem.name,
                        phone: newItem.phone,
                        date: newItem.date,
                        isChecked: false,
                        type: newItem.type,
                        idKay: itemId
                    },
                    ...storedItems
                ];
                setBack(updatedItems);
                localStorage.setItem(`myArrayBack${name}`, JSON.stringify(updatedItems));
                setloading(false)
            }
        };
        
        const handleChildRemoved = (snapshot) => {
            const newItem = snapshot.val();
            const itemId = snapshot.key;
            const updatedItems = storedItems.filter((item) => item.idKay !== itemId)
            setBack(updatedItems);
            localStorage.setItem(`myArrayBack${name}`, JSON.stringify(updatedItems));
        }
        onChildAdded(itemsRef, handleChildAdded);
        onChildRemoved(itemsRef, handleChildRemoved);
    }
    const lisnDBBackB2b = () => {
        const dbRef = ref(getDatabase());
        const itemsRef = child(dbRef, `/ron_mas/ron/bTowb/`);
        const storedItems = JSON.parse(localStorage.getItem(`myArrayBackBtowB`)) || [];
        const handleChildAdded = (snapshot) => {
            const newItem = snapshot.val();
            const itemId = snapshot.key;
            // Check if the item already exists in the list
            const isItemExist = storedItems.some(item => item.idKay === itemId);
            if (!isItemExist) {
                const updatedItems = [
                    {
                        name: newItem.name,
                        phone: newItem.phone,
                        date: newItem.date,
                        isChecked: false,
                        type: newItem.type,
                        idKay: itemId
                    },
                    ...storedItems
                ];
                setBtowB(updatedItems);
                localStorage.setItem(`myArrayBackBtowB`, JSON.stringify(updatedItems));
                setloading(false)
            }
        };

        const handleChildRemoved = (snapshot) => {
            const newItem = snapshot.val();
            const itemId = snapshot.key;
            const updatedItems = storedItems.filter((item) => item.idKay !== itemId)
            setBack(updatedItems);
            localStorage.setItem(`myArrayBackBtowB`, JSON.stringify(updatedItems));
        }
        onChildAdded(itemsRef, handleChildAdded);
        onChildRemoved(itemsRef, handleChildRemoved);
    }


    const getList = () => {
        const storedArray = JSON.parse(localStorage.getItem(`myArrayBack${name}`)) || '';
        if (storedArray) {
            setColorC("green");
            setColorB("white");
            setBack(storedArray);
            setloading(false)
        } else {
            setColorC("green");
            setColorB("white");
            setBack([]);
            setloading(false)
        }
    }

    const getListBtB = () => {
        const storedArray = JSON.parse(localStorage.getItem(`myArrayBackBtowB`)) || '';
        if (storedArray) {
            setColorC("white");
            setColorB("green");
            setBtowB(storedArray);
            setloading(false)

        } else {
            setColorC("white");
            setColorB("green");
            setBtowB([]);
            setloading(false)

        }
    }


    const updateUser = (id, chek) => {
        let storedArray = [];
        const localStorageArray = JSON.parse(localStorage.getItem(`myArrayBack${name}`)) || [];
        storedArray = localStorageArray.map((item, index) => {
            if (item.idKay == id) {
                localStorageArray[index].isChecked = chek;
                localStorage.setItem(`myArrayBack${name}`, JSON.stringify(localStorageArray));
                setBack(localStorageArray);
            }
        })
    }

    const updateUserBtB = (id, chek) => {
        let storedArray = [];
        const localStorageArray = JSON.parse(localStorage.getItem(`myArrayBackBtowB`)) || [];
        storedArray = localStorageArray.map((item, index) => {
            if (item.idKay == id) {
                localStorageArray[index].isChecked = chek;
                localStorage.setItem(`myArrayBackBtowB`, JSON.stringify(localStorageArray));
                setBtowB(localStorageArray);
            }
        })
    }

    const removeItem = (idRemove) => {
        let storedArray = [];
        storedArray = arrayDataBack.filter((item, index) => item.idKay !== idRemove)
        setBack(storedArray);
    };



    const doApi = async () => {
        const db = getDatabase();
        const myListData = [];

        const dbRef = ref(db, `ron_mas/${name}/back/`);
        const snapshot = await get(dbRef);
        if (snapshot.exists()) {
            const firebaseData = snapshot.val();
            const dataArray = Object.entries(firebaseData);
            for (const [idKay, item] of dataArray) {
                myListData.push({
                    name: item.name,
                    phone: item.phone,
                    date: item.date,
                    idKay: idKay
                });
            }
            setloading(false)
            setBack(myListData.reverse());
        } else {
            setloading(false)
        }
    }

    return (
        <div className='mb-3'>
            {loading &&
                <div className="charging-circle-container">
                    <div className="charging-circle"></div>
                </div>
            }
            {
               ( name == "ron" || name == "touchpay")&&
                <div className=' d-flex justify-content-evenly'>
                    <div >
                        <div className="d-flex justify-content-center mt-4 mt-lg-5 an">
                            <button
                                onClick={getList}
                                style={{ fontWeight: 'bolder', fontSize: '18px', direction: "rtl", color: styleButtonBtc }}
                                className=" btn-add pt-lg-2 pb-lg-2 ps-5 text- text-center pe-5 pt-2 pb-2 ">
                                B2C
                            </button>
                        </div>
                    </div>
                    <div>
                        <div className="d-flex justify-content-center mt-4 mt-lg-5 an">
                            <button
                                onClick={getListBtB}
                                style={{ fontWeight: 'bolder', fontSize: '18px', direction: "rtl", color: styleButtonBtB }}
                                className=" btn-add pt-lg-2 pb-lg-2 ps-5 text-center pe-5 pt-2 pb-2 ">
                                B2B
                            </button>
                        </div>
                    </div>

                </div>
            }

            {
                (  ( name == "ron" || name == "touchpay") && styleButtonBtc == "green") && arrayDataBack != undefined && arrayDataBack.map((person, index) => (
                    <ItemBack updateUser={updateUser} b2b={"false"} key={index} removeItem={removeItem} person={person} />
                ))
            }

            {
                ( ( name == "ron" || name == "touchpay") && styleButtonBtc == "green") && ((arrayDataBack.length == 0 && loading == false) && <div className='mt-3 p-4'>
                    <div className=' d-flex justify-content-center mb-2'>
                        <IoIosPeople className=' text-dark' size={45} />
                    </div>
                    <div className=' d-flex justify-content-center'>
                        <h2 className=' text-dark'>אין כרגע לקוחות</h2>
                    </div>
                </div>)
            }

            {
                ( ( name == "ron" || name == "touchpay") && styleButtonBtB == "green") && arrayDataBtowB != undefined && arrayDataBtowB.map((person, index) => (
                    <ItemBack updateUserBtB={updateUserBtB} b2b={"true"} key={index} removeItem={removeItem} person={person} />
                ))
            }

            {
                ( ( name == "ron" || name == "touchpay") && styleButtonBtB == "green") && ((arrayDataBtowB.length == 0 && loading == false) && <div className='mt-3 p-4'>
                    <div className=' d-flex justify-content-center mb-2'>
                        <IoIosPeople className=' text-dark' size={45} />
                    </div>
                    <div className=' d-flex justify-content-center'>
                        <h2 className=' text-dark'>אין כרגע לקוחות</h2>
                    </div>
                </div>)
            }



            {
                ( name != "ron" && name != "touchpay")  &&
                arrayDataBack != undefined && arrayDataBack.map((person, index) => (
                    <ItemBack updateUser={updateUser} b2b={"false"} key={index} removeItem={removeItem} person={person} />
                ))
            }

            {
                ( name != "ron" && name != "touchpay")  && ((arrayDataBack.length == 0 && loading == false) && <div className='mt-3 p-4'>
                    <div className=' d-flex justify-content-center mb-2'>
                        <IoIosPeople className=' text-dark' size={45} />
                    </div>
                    <div className=' d-flex justify-content-center'>
                        <h2 className=' text-dark'>אין כרגע לקוחות</h2>
                    </div>
                </div>)
            }

        </div>
    )
}
