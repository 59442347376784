import React from 'react'

import { LuPartyPopper } from "react-icons/lu";
import { useNavigate, useParams } from 'react-router-dom';

export default function FinishShevah(props) {
    const nav = useNavigate()
    let { name } = useParams();
    const backReshi = () => {
        nav(`/${name}`)
    }

    return (
        <div>
            <div id="" className="content">
                <LuPartyPopper color='yellow' className=' mt-2'  size={50} />
                <h4 className='titlecheck mt-2'>תודה שהשתמשת בשירותינו :) אחד מנציגנו יצור איתך קשר בהקדם</h4>
                <div className='' style={{ direction: 'rtl' }}>
                    <button onClick={() => backReshi()} style={{ fontSize: '18px' }} className="btnFinish text-white  p-1  pe-5 ps-5 anBack  mt-3 "> סיום </button>
                </div>
            </div>

        </div>
    )
}
