import React, { useState } from 'react'
import { FaPhoneAlt } from "react-icons/fa";
import { getDatabase, ref, remove, child, get, update } from "firebase/database";
import { useParams } from 'react-router-dom';
import '../lid.css'


export default function ItemBack(props) {
    const [isOpenRemove, setIsOpenRemove] = useState(false)
    const [isChecked, setIsChecked] = useState(false);
    let { name } = useParams();

    const handleCheckboxChange = () => {
        const db = ref(getDatabase());
        const dbb = getDatabase();
        if(props.b2b != "true"){
            props.updateUser(props.person.idKay, !isChecked);
            get(child(db, `/ron_mas/${name}/back/`)).then((snapshot) => {
                const itemRefUp = ref(dbb, `/ron_mas/${name}/back/` + props.person.idKay);
                update(itemRefUp, {
                    'isChecked': !isChecked
                }, (error) => {
                    if (error) {
                        console.error("Error updating data:", error);
                    } else {
                        //setUpdateSuccess(true);
                    }
                });
            }).catch((error) => {
                console.error(error);
            });
        }else{
            props.updateUserBtB(props.person.idKay, !isChecked);
            get(child(db, `/ron_mas/ron/bTowb/`)).then((snapshot) => {
                const itemRefUp = ref(dbb, `/ron_mas/ron/bTowb/` + props.person.idKay);
                update(itemRefUp, {
                    'isChecked': !isChecked
                }, (error) => {
                    if (error) {
                        console.error("Error updating data:", error);
                    } else {
                        //setUpdateSuccess(true);
                    }
                });
            }).catch((error) => {
                console.error(error);
            });
        }
        setIsChecked(!isChecked);
    };

    


    const toggleBoxFinish = () => {
        setIsOpenRemove(false);
    };

    const clickRemove = () => {
        setIsOpenRemove(true);
    }
    const clickRemoveItem = () => {
        const db = getDatabase();
        const itemRef = ref(db, `/ron_mas/${name}/back/` + props.person.idKay);
        props.removeItem(props.person.idKay)
        remove(itemRef);
        setIsOpenRemove(false)
    }

    const handleClick = (phone) => {
        window.location = `tel:${phone}`;
    };

    return (
        <div className='d-flex justify-content-center an' style={{ direction: 'rtl' }}>
            <div className=" broderLog p-2 mt-4 bg-white col-11 col-xl-5 ">
                <div className=' d-flex justify-content-between '>
                    <div className=' d-flex'>
                        <h6 className='mt-1 me-2'> {props.person.name}  </h6>
                        <h6 className='mt-1 me-2'> | </h6>
                        <h6 className='mt-1 me-2'> {props.person.date}  </h6>
                        {/* <h6 onClick={() => handleClick(props.person.phone)} className='mt-1 me-2'>{props.person.phone}</h6> */}
                    </div>
                    <div className=' d-flex align-baseline'>
                        {/* <label className=''>
                            <Switch className='' size="small" onChange={handleCheckboxChange} checked={props.person.isChecked}/>
                        </label> */}
                        <label className='checkbox-container'>
                            <input
                                className='ms-2'
                                type="checkbox"
                                checked={props.person.isChecked}
                                onChange={handleCheckboxChange}
                            />

                        </label>
                        <button style={{ background: "none", border: "none", textDecoration: "none" }} className='text-dark ms-1'>
                            <FaPhoneAlt onClick={() => handleClick(props.person.phone)} size={21} className=' me-2' />
                        </button>

                    </div>
                </div>
                {
                    (props.person.type == "שבח") &&
                    <h6 className='mt-1 me-2'>({props.person.type})</h6>
                }
            </div>

            {
                isOpenRemove && (
                    <div className="dialog-container" style={{ direction: 'rtl' }}>

                        <div className="dialog-content">
                            <div className=' d-flex justify-content-start'>
                                <button style={{ background: 'none', border: 'none', fontWeight: 'bold', fontSize: '20px' }} onClick={toggleBoxFinish} className='text-danger'>X</button>
                            </div>
                            <div className='p-3'>
                                <h5>בטוח שברצונך למחוק ?</h5>
                                <div className=' d-flex justify-content-between mt-4 ms-2'>
                                    <button onClick={clickRemoveItem} className='mt-1 me-2 custom-button'>כן</button>
                                    <button onClick={toggleBoxFinish} className='mt-1 me-2 custom-button'>בטל</button>
                                </div>

                            </div>
                        </div>

                    </div>
                )

            }

        </div>
    )
}
