import React, { useEffect, useState } from 'react';
import '../step.css'
import { getDatabase, ref, get, child } from 'firebase/database';
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { useNavigate, useParams } from 'react-router-dom';
import ShowLidim from '../menger/ShowLidim';
import { IoIosLogIn } from "react-icons/io";
import { FiLogIn } from "react-icons/fi";

import '../loading.css';


export default function Login(props) {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [showComponent, setShowComponent] = useState(false);
    const [showPage, setShowPage] = useState(false);
    const [loading, setLoad] = useState(false);
    const [phone, setPhone] = useState(0);
    const { name } = useParams();
    const nav = useNavigate();

    const handleLogin = async (e) => {
        e.preventDefault(); // Prevent default form submission behavior
        const auth = getAuth();
        if (localStorage.getItem(`email${name}`) == email && localStorage.getItem(`password${name}`) == password) {
            setLoad(true);
            signInWithEmailAndPassword(auth, email, password)
                .then((userCredential) => {
                    localStorage.setItem(`emailLogin${name}`, email)
                    localStorage.setItem(`passwordLogin${name}`, password)
                    setShowComponent(true)
                    setError("מתחבר")
                    setLoad(false);
                })
        }
        else {
            setError("אחד או יותר מפרטי ההזדהות שגויים")
            setLoad(false);
            setTimeout(() => {
                setError("")
            }, 3000);

        }
    };

    useEffect(() => {
        if (localStorage.getItem("nameCPN") == name) {
            setShowPage(true);
        }
        doApi()
        if (localStorage.getItem(`emailLogin${name}`) != null && localStorage.getItem(`passwordLogin${name}`) != null) {
            setEmail(localStorage.getItem(`emailLogin${name}`));
            setPassword(localStorage.getItem(`passwordLogin${name}`));
        }
    }, [])

    const doApi = () => {
        const dbRef = ref(getDatabase());
        get(child(dbRef, `ron_mas/`)).then((snapshot) => {
            if (snapshot.hasChild(name)) {
                get(child(dbRef, `ron_mas/${name}/userWork`)).then((snapshot) => {
                    if (snapshot.val() == "on") {
                        localStorage.setItem('showPage', "true");
                    } else {
                        nav(`/Error404`);
                        setShowPage(true)
                        localStorage.setItem('showPage', "false");
                    }
                }).catch((error) => {
                    console.error(error);
                });
            } else {
                nav(`/Error404`);
                localStorage.setItem('showPage', "false");
            }
        }).catch((error) => {
            console.error(error);
        });

    }

    const nextPageLog = () => {
        nav(`/${name}/`)
    }

    const callCompny = () => {
        window.location = `tel:${phone}`;
    }

    return (
        showPage ? <div>
            {!showComponent ? (
                <div className='bodyl anBack'>
                    <div className=" bg-white d-flex justify-content-between ">
                        <div className='position-relative  ps-2 mt-2'>
                            <img
                                style={{ transform: 'scaleX(-1)' }}
                                className='animated-button '
                                src={`${process.env.PUBLIC_URL}/imges/namelogot.png`}
                                alt="Name Logo"
                                height={40}
                            />
                        </div>
                        <div className=" pe-2 animated-b ">
                            <button onClick={nextPageLog} style={{ fontWeight: 'bolder' }} className='btn-login p-1 mb-1  mt-2  '>
                                <FiLogIn size={22} />
                                {/* style={{ transform: 'scaleX(-1)' }} */}
                            </button>
                        </div >
                    </div>
                    <div className='position-relative  d-lg-flex justify-content-lg-center  p-3 mt-3'>
                        <div className='backimageBoxLogin   col-lg-8 shadow'>
                            <div className='container d-flex justify-content-center align-items-center  ' style={{ direction: 'rtl' }}>
                                <div className=" col-11 col-xl-5 mt-xl-4 p-3 mt-4">
                                    <h1 className='textLog animated-b text-white mb-3'>התחברות</h1>
                                    <form onSubmit={handleLogin}>
                                        <div className="form-group  mt-4 an">
                                            <input
                                                type="email"
                                                className="form-control"
                                                placeholder="כתובת אימייל"
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                            />
                                        </div>
                                        <div className="form-group mt-4 an">
                                            <input
                                                type="password"
                                                className="form-control"
                                                placeholder="סיסמה"
                                                value={password}
                                                onChange={(e) => setPassword(e.target.value)}
                                            />
                                        </div>
                                        {error && <div className="alert alert-danger">{error}</div>}
                                        <button type="submit" className="animated-button pe-2 ps-2 mt-4 p-2 pe-5 ps-5 btnCall">כניסה</button>
                                    </form>

                                </div>
                            </div>

                        </div>
                    </div>
                    {loading &&
                        <div className="charging-circle-container">
                            <div className="charging-circle"></div>
                        </div>
                    }
                    <div className="me-3 mt-3 d-flex  justify-content-center align-baseline fixed-bottom p-3 an">
                        <button onClick={() => callCompny()} style={{ fontWeight: 'bolder', fontSize: '18px' }} className='pe-3 ps-3 mt-3 btnCall border-3'>
                            צור קשר
                            {/* צור קשר <MdContactPhone className='mb-1  align-bottom' size={23} /> */}
                        </button>
                    </div >
                </div>


            ) : (
                <div>
                    <ShowLidim />
                </div>
            )}

        </div> : <div className="charging-circle-container">
            <div className="charging-circle"></div>
        </div>



    );
};
