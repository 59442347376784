import React from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { VscServerProcess } from "react-icons/vsc";

const ScrollAnimation = () => {
  const { ref, inView } = useInView({
    threshold: 0.1, // Adjust to trigger the animation sooner or later
  });

  return (
    <motion.div
      ref={ref}
      initial={{ opacity: 0, y: 50 }}
      animate={inView ? { opacity: 1, y: 0 } : {}}
      transition={{ duration: 0.5 }}
      style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
    >
      <div className=' d-flex justify-content-center'>
        <h3 style={{ direction: "rtl" }} className='  titlecheck p-1  col-lg-8 text-center  mt-2 mt-lg-4'>
          בתוך מספר שלבים פשוטים תדעו את הסכום שאתה זכאי לקבל !
          הנה השלבים של המערכת שלנו:</h3>
      </div>
    </motion.div>
  );
};

export default ScrollAnimation;
