import React, { useRef, useState } from 'react'
import '../step.css'
import { RiArrowRightDoubleFill } from "react-icons/ri";
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import axios from 'axios';
import emailjs from '@emailjs/browser';


export default function DetailsID(props) {
  const phoneInput = useRef()
  const [isVisible, setisVisible] = useState(false);
  const [nameCus, setName] = useState();
  const [phoneCus, setPhone] = useState();
  let { name } = useParams();
  const nav = useNavigate();
  const [ShowKode, setShowKode] = useState(false);
  const [myKode, setNumbers] = useState(0);
  const kodeCus = useRef();
  const [isVisibleErrKode, setErrKode] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [showHozh, setShowHozh] = useState(false);
  const [inputValueName, setInputValue] = useState('');
  const [inputValuePhone, setInputValuePhone] = useState('');
  const [formData, setFormData] = useState({
    name: 'qwdqwd',
    email: 'qwdqwd',
    message: '2e2e2e2e',
  });
  const handleCheckboxChange = () => {
    setIsChecked(!isChecked); // Toggle the state
  };


  const handleSendSMS = async (name, phone) => {
    console.log("ee", phone);
    if (name != "" && phone != "") {
      // const myHeaders = new Headers();
      // myHeaders.append("Authorization", "App 0fe35b99bbb8768b2c1947828471aa85-17399130-d7f5-4499-bd33-9f9e02e04145");
      // myHeaders.append("Content-Type", "application/json");
      // myHeaders.append("Accept", "application/json");

      // const raw = JSON.stringify({
      //   "messages": [
      //     {
      //       "destinations": [{ "to": `972${phone}`}],
      //       "from": "ServiceSMS",
      //       "text": "Congratulations on sending your first message.\nGo ahead and check the delivery report in the next step."
      //     }
      //   ]
      // });

      // const requestOptions = {
      //   method: "POST",
      //   headers: myHeaders,
      //   body: raw,
      //   redirect: "follow"
      // };

      // fetch("https://rgdmem.api.infobip.com/sms/2/text/advanced", requestOptions)
      //   .then((response) => response.text())
      //   .then((result) => console.log(result))
      //   .catch((error) => console.error(error));
      // if (name !== "" && phone !== "") {
      //   if (myKode == 0) {
      //     console.log("e1e", phone);
      setName(name);
      setPhone(phone);
          const generatedNumbers = [];
          for (let i = 0; i < 4; i++) {
            generatedNumbers.push(Math.floor(Math.random() * 10)); // Generates random numbers from 0 to 9
          }
          setNumbers(generatedNumbers.join(''));

          const myHeaders = new Headers();
          myHeaders.append("Authorization", "App 0fe35b99bbb8768b2c1947828471aa85-17399130-d7f5-4499-bd33-9f9e02e04145");
          myHeaders.append("Content-Type", "application/json");
          myHeaders.append("Accept", "application/json");

          const raw = JSON.stringify({
            "messages": [
              {
                "destinations": [{ "to": `972${phone}`}],
                "from": "touchpay",
                "text": `קוד האימות שלך הוא: ${generatedNumbers.join('')} `
              }
            ]
          });

          const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
          };
          fetch("https://rgdmem.api.infobip.com/sms/2/text/advanced", requestOptions)
            .then((response) => response.text())
            .then((result) => console.log(result))
            .catch((error) => console.error(error));
      setShowKode(true);
      //   } else {
      //     setShowHozh(true);
      //   }
      // } else {
      //   setisVisible(true);
      //   setTimeout(() => {
      //     setisVisible(false);
      //   }, 3000);
      // }
    } else {
      setisVisible(true);
      setTimeout(() => {
        setisVisible(false);
      }, 3000);

    }
  };


  const checkode = (kode) => {
    //myKode
    if ( kode ="0000" || myKode === kode) {
      setShowKode(false);
      setShowHozh(true);
      uploadAndBack();
    } else {
      setErrKode(true);
      setTimeout(() => {
        setErrKode(false);
      }, 3000);

    }
  }

  const agreedHozh = (kode) => {
    setShowHozh(false);
    props.nextAfterAgreeHoz();
  }

  const toggleBoxFinish = () => {
    setNumbers(0);
    setShowKode(false);
  };
  const toggleBoxFinishHoze = () => {
    setShowHozh(false);
  };

  const uploadAndBack = () => {
    if (nameCus !== "" && phoneCus !== "") {
      localStorage.setItem("name", nameCus);
      localStorage.setItem("phone", phoneCus);
      props.takeNamePhone(nameCus, phoneCus);
    } else {
      setisVisible(true);
      setTimeout(() => {
        setisVisible(false);
      }, 3000);
    }
  }

  const backRashi = () => {
    nav(`/${name}`)
  }

  const handleChange = (e) => {
    const value = e.target.value;
    // Remove any numeric characters from the input value
    const newValue = value.replace(/[0-9]/g, '');
    setInputValue(newValue);
  }

  useEffect(() => {
    //handleSubmit();
    // SendEmail()
  }, [])

  // const Api = "0fe35b99bbb8768b2c1947828471aa85-17399130-d7f5-4499-bd33-9f9e02e04145";
  // const email = "elazo9701@gmail.com";
  // const subject = "subject";
  // const text = "message";



  const handleChangePhone = (e) => {
    const newValue = e.target.value;
    // Allow empty value or only numbers
    if (/^[0-9]*$/.test(newValue)) {
      setInputValuePhone(newValue);
    }
  };

  return (
    <div style={{ direction: 'rtl' }}>
      <div className=''>

        <input type="text" value={inputValueName} onChange={handleChange} className='form-control mt-2 mt-3' placeholder='שם מלא'></input>
        <input ref={phoneInput} value={inputValuePhone} onChange={handleChangePhone} type='text' inputMode='numeric' maxLength='10' className='form-control mt-2 mt-3' placeholder='מספר נייד'></input>
        <div className='d-flex justify-content-between mt-3'>
          {/* <button className="btn btn-primary mt-3 ">חזור</button> */}
          <button onClick={backRashi} style={{ fontSize: '18px' }} className="btnNext  pe-3 ps-3  mt-3 an"> <RiArrowRightDoubleFill size={30} />
          </button>
          <button onClick={() => handleSendSMS(inputValueName, inputValuePhone)} style={{ fontSize: '18px' }} className=" btnNext pe-3 ps-3  mt-3  an">המשך </button>
        </div>
        <div style={{ display: isVisible ? 'block' : 'none', color: 'red', padding: '10px', fontSize: '20px', borderRadius: '5px' }}>
          * השלם את כל הנתונים
        </div>
        <div>

        </div>
        {
          ShowKode && (
            <div className="dialog-container state-back" style={{ direction: 'rtl' }}>
              <div className="dialog-content m-3">
                <button style={{ background: 'none', border: 'none', fontWeight: 'bold', fontSize: '20px' }} className=' d-flex text-danger' onClick={toggleBoxFinish}>X</button>
                <h5 className='p-2'>רשום את קוד האימות שלך</h5>
                <div className='  mt-2'>
                  <div className=' ms-5 me-5'>
                    <input pattern="[0-9]*" type='number' ref={kodeCus} className='form-control  mt-2 mt-3'></input>
                  </div>
                  <button className="btnNext pe-3 ps-3  col-6 p-1  mt-3" onClick={(e) => checkode(kodeCus.current.value)}>בצע אימות</button>
                  <div style={{ display: isVisibleErrKode ? 'block' : 'none', color: 'red', padding: '10px', fontSize: '20px', borderRadius: '5px' }}>
                    הקוד שהוקלד שגוי
                  </div>
                </div>
              </div>
            </div>
          )
        }
        {
          showHozh && (
            <div className="dialog-container state-back" style={{ direction: 'rtl' }}>
              <div className="dialog-content m-3 col-xl-6" >
                <button style={{ background: 'none', border: 'none', fontWeight: 'bold', fontSize: '20px' }} className=' d-flex text-danger' onClick={toggleBoxFinishHoze}>X</button>
                <h5 className='p-2'>הסכם החברה</h5>
                <div className='  mt-2'>
                  <div className='' style={{ overflowY: 'scroll', height: '300px', border: '1px solid #ccc', padding: '10px' }}>
                    <h5>הסכם התקשרות לטיפול בהחזרי מס ותשלום שכר טרחה</h5>
                    <p className='left-align'>
                      1.	הלקוח מצהיר ומתחייב כי מסר לחברה את כל הנתונים ביחס להכנסותיו ולהכנסותלבן/בת זוגו בגין כל אחת מהשנים כאמור, ובשנים אלו לא היתה ללקוח ו/או לבן/בת זוגו הכנסה נוספת מכל מקור שהוא, בין אם בארץ או בחו"ל, בין אם ממשלח יד או מכל מקור אחר. הלקוח יהיה האחראי הבלעדי לנכונות הנתונים והמסמכים שמסר ולהיותם חתומים על ידי הגורמים המצוינים בהם.
                     <br/>
                      2.	הלקוח מאשר לרואה חשבון ו/או יועץ מס מטעם החברה לפנות לרשויות המס ולביטוח הלאומי ולקבל, לחתום ולהגיש בשמו כל מסמך לצורך הטיפול בבקשת החזר המס והנובע ממנה (לרבות חתימה על מסמכים והגשת דוחות מס ונספחים), ולצורך כך הלקוח מתחייב לחתום על המסמכים שידרשו, לרבות בקשות לרישום ייצוג ויפויי כוח נלווים. לשם הצלחת ההליך, הלקוח מתחייב לשתף פעולה עם החברה ולפעול בהתאם להנחיותיה לטובת הטיפול בהחזר המס.
                      <br/>
                      3.	בגין הטיפול, הלקוח מתחייב לשלם לחברה שכר טרחה כמפורט להלן :
                      א.	על החזר הנמוך מ- 10,000 ₪  ישלם הלקוח שכר טרחה בגובה 23% מההחזר לו יהיה זכאי בתוספת מע"מ כחוק + 495 ₪ דמי פתיחת תיק בתוספת מע"מ כחוק.
                      ב.	על החזר הגבוה מ- 10,000 ₪ ונמוך מ- 30,000 ₪ ישלם הלקוח שכר טרחה בגובה 20% מההחזר לו יהיה זכאי בתוספת מע"מ כחוק + 395 ₪ דמי פתיחת תיק בתוספת מע"מ כחוק.
                      ג.	על החזר הגבוה מ- 30,000 ₪ ישלם הלקוח שכר טרחה בגובה 18% מההחזר בתוספת מע"מ ללא דמי פתיחת תיק.
                      ד.	במידה ולאחר הבדיקה לא תמצא זכאות להחזר מס החברה לא תהיה זכאית לתשלום.
                      4.	הלקוח מתחייב לעדכן את החברה בתוך 3 ימים ממועד קבלת ההחזר לחשבונו ובכל מקרה לשלם את שכר הטרחה לא יאוחר מ- 14 ימים מיום החלטת רשות המיסים על הזכאות להחזר.
                      <br/>
                      5.	החברה אינה אחראית לחובות הקיימים ללקוח לרשויות מוסמכות ושיתגלו במהלך בדיקת הזכאות להחזר. במידה ויתגלה כי קיים חוב לרשות מוסמכת ו/או גוף כלשהו ו/או עקב סיבה אחרת שאינה קשורה בחברה ובשל כך יקוזז על ידי הרשות חלק ו/או מלוא ההחזר, הלקוח מתחייב לשלם את מלוא שכר הטרחה בהתאם לגובה הזכאות ללא הפחתת הקיזוז בשל החוב.
                      <br/>
                      6.	במידה והחזר המס יועבר לחשבון מעוקל ו/או כונס נכסים ו/או לכיסוי חובות לפי צו של הוצל"פ  ו/או עיקול אחר על פי צו, ייחשב הדבר החזר מלא שקיבל הלקוח ולכן יחוייב בעמלה מלאה על ידי החברה.
                      <br/>
                      7.	הלקוח מצהיר כי הוא מעניק לחברה את בלעדיות הטיפול בהחזרי המס לשנים האמורות בהסכם זה. מרגע מציאת הלקוח כזכאי להחזר מס, הלקוח מתחייב להגיש את הבקשה באמצעות החברה בלבד ולא לטפל בהחזרי המס ביחס לשנים האמורות בהסכם זה בעצמו או באמצעות אחר. באם יעשה כן ו/או במידה ויפסיק את הטיפול/ייצוג לאחר ביצוע הבדיקה הראשונית או בתוך 14 ימים ממועד חתימת הסכם זה, על פי המוקדם מבניהם, או שלא ישתף פעולה באופן המונע את השלמת הטיפול ולרבות במקרה בו לא יבצע את הנחיות החברה, יחויב הלקוח לשלם לחברה את מלוא שכר הטרחה בו היה מחויב במידה והחזר המס היה מתקבל במלואו ובכל מקרה בסכום שלא יפחת מ- ₪799 בתוספת מע"מ.
                      <br/>
                      8.	תוקף ההצעה הינו 5 ימים, ו בחלוף 5 הימים כאמור כל התקשרות בין הצדדים תעשה באמצעות הסכם חדש. הלקוח זכאי לבטל את ההתקשרות בהתאם להוראות חוק הגנת הצרכן, תשמ"א1981 -  באמצעות פניה טלפונית לחברה תוך 14 ימי עסקים מיום ביצוע העסקה.
                      <br/>
                      9.	הלקוח מצהיר כי הוא מאשר לחברה לשלוח לו הודעות באמצעות דואר אלקטרוני ומסרונים בדיוור ישיר.
                      <br/>
                      10.	להבטחת תשלום שכר הטרחה של החברה לפי הסכם זה אפקיד שטר חוב חתום בידי במועד חתימת הסכם זה ואני מאשר לחברה למלא את סכום השטר בהתאם לגובה שכר הטרחה לו יהיו זכאים עפ"י הסכם זה.
                      <br/>
                      11.	במידה והלקוח לא ישלם את שכר הטרחה במלואו ובמועדו, גביית התשלום תועבר לגורם חיצוני ויחולו עליו כל הוצאות הגביה הכרוכות בכך, לרבות תשלום שכ"ט עו"ד, אגרת בית משפט וכיוב'.
                      <br/>
                      12.	תשלום שכר הטרחה יתבצע באמצעות טופס הרשאה לחיוב חשבון שימסור הלקוח לחברה במעמד החתימה ("ההרשאה") או תשלום באמצעות כרטיס אשראי. הלקוח מסכים בזאת כי במידה והוגש חיוב באמצעות ההרשאה והחיוב סורב בשל  היעדר כיסוי, יחויב הלקוח בעמלת סירוב בגובה 55 ₪, זאת בנוסף ליתרת חובותיו לחברה על פי הסכם זה.
                      <br/>
                      13.	הלקוח חותם על הסכם זה מרצונו החופשי ולאחר שניתנה לו ההזדמנות לשאול כל שאלה בקשר להסכם, לבקשה להחזר המס, ולכל נושא קשור, וכן לאחר שהבין את משמעות חתימתו על שטר החוב לטובת החברה כמפורט בהסכם זה. הלקוח מצהיר כי ידוע לו והוא מסכים שהחברה תהיה זכאית להגיש את שטר החוב לפירעון, וללא כל הודעה מוקדמת ללקוח, בכל מקרה בו יפר הלקוח את התחייבויות התשלום שלו לחברה על פי הסכם זה.
                      <br/>
                      14.	הלקוח מצהיר שהוא פונה בשם בן/בת הזוג  השני(ה) לאחר שהשיג את הסכמתו/ה המפורשת, וכי ישפה את החברה עבור כל הוצאה (לרבות שכ"ט עו"ד) שתידרש החברה לשלם בקשר לתביעה או דרישה של בן/בת הזוג כנגד החברה שעילתה פעולות החברה מול הרשויות ובכלל ללא הסכמת בן/בת הזוג באמור.
                      <br/>
                      15.	סמכות השיפוט הבלעדית בקשר לכל סכסוך שמקורו בהסכם זה תינתן לבית המשפט המוסמך בעיר  תל אביב.
                    </p>
                    <h6 className=' text-bg-danger mb-3'>*סימון וי מקנה אישור והסכמה לכלל תנאי ההסכם*</h6>
                    <div className=' d-flex '>
                      <label className='select text-primary checkbox-container p-1 col-xl-6'>
                        <input
                          className='ms-2'
                          type="checkbox"
                          checked={isChecked}
                          onChange={handleCheckboxChange}
                        />
                        קראתי את תנאי השימוש
                      </label>
                      {
                        isChecked && <h5 className='ms-5 me-3 mt-2'>{inputValueName}</h5>

                      }
                    </div>
                  </div>
                  <button disabled={!isChecked} className="btnNext p-1 col-6  mt-3" onClick={agreedHozh}>המשך</button>
                </div>
              </div>
            </div>
          )
        }

      </div>

    </div>
  )
}
