import React from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { VscServerProcess } from "react-icons/vsc";
import { GiPodiumWinner } from "react-icons/gi";

const ScrollAnimationWinner = () => {
  const { ref, inView } = useInView({
    threshold: 0.1, // Adjust to trigger the animation sooner or later
  });

  return (
    <motion.div
      ref={ref}
      initial={{ opacity: 0, y: 50 }}
      animate={inView ? { opacity: 1, y: 0 } : {}}
      transition={{ duration: 0.5 }}
      style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
    >
      <div className='pe-3 pt-1 mt-lg-5 ms-4'>

        <h1 className='titleFont'>תוצאה מדויקת זה ניצחון!</h1>

        <h6 className='textPage container me-lg-5 col-lg-10 mt-4' style={{ direction: 'rtl', fontSize: '21px' }}>
          <GiPodiumWinner color='blue' size={30} />
          זה הניצחון של touchpay שבמילוי פרטים אישיים וטפסי 106 נוכל להציג לך תוצאה מדויקת של סכום הזכאות של הלקוח שלך.
        </h6>

        <h6 className='textPage container me-lg-5 col-lg-10 ps-5 mt-4' style={{ direction: 'rtl', fontSize: '21px' }}>
          <GiPodiumWinner color='blue' size={30} />
          באפשרותך כרואה חשבון להחליט אם הלקוח שלך יבצע את תהליך הבדיקה לבד או שתוכל לבצע לו אתה את הבדיקה.
        </h6>

        <h6 className='textPage container me-lg-5 col-lg-10 ps-5 mt-4' style={{ direction: 'rtl', fontSize: '21px' }}>
          <GiPodiumWinner color='blue' size={30} />
          כל המידע של הלקוחות שלך והטפסי 106 שהעלו במהלך הבדיקה הכל נשמר במערכת שלך בלבד!
        </h6>

      </div>
    </motion.div>
  );
};

export default ScrollAnimationWinner;
