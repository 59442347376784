import React, { useEffect, useState } from 'react'
import '../lid.css'
import '../home.css'
import ReturnToMas from '../afterChack/ReturnToMas';
import { useNavigate, useParams } from 'react-router-dom';
import { FaHome } from "react-icons/fa";
import '../loading.css';
import CallBack from '../backNumber/callBack';
import AddCus from '../addMenger/addMenger';
import { FiLogIn } from "react-icons/fi";


export default function ShowLidim() {
    const allCategories = ["זכאי", "לא זכאי", "השאירו פרטים", "שבח"]
    const allCategoriesRon = ["זכאי", "לא זכאי", "השאירו פרטים", "שבח", "ניהול"]
    const [Catgories, setCatgories] = useState(allCategories);
    const [CatgorIndex, setIndex] = useState(0);
    const { name } = useParams();
    const nav = useNavigate()
    useEffect(() => {
        if (name === "ron") {
            setCatgories(allCategoriesRon)

        } else {
            setCatgories(allCategories)
        }
    }, [])

    const backReashi = () => {
        nav(`/${name}`)

    }

    const nextPageLog = () => {
        nav(`/${name}/`)
    }

    return (
        <div className='bodyl' style={{ overflow: 'auto', height: 'inherit', display: 'block' }}>
            <div className='bodyl'>
                <div className=" bg-white d-flex justify-content-between ">
                    <div className='position-relative  ps-2 mt-2'>
                        <img
                            style={{ transform: 'scaleX(-1)' }}
                            className='animated-button '
                            src={`${process.env.PUBLIC_URL}/imges/namelogot.png`}
                            alt="Name Logo"
                            height={40}
                        />
                    </div>
                    <div className=" pe-2 animated-b ">
                        <button onClick={nextPageLog} style={{ fontWeight: 'bolder' }} className='btn-login p-1 mb-1  mt-2  '>
                            <FiLogIn size={22} />
                            {/* style={{ transform: 'scaleX(-1)' }} */}
                        </button>
                    </div >
                </div>
                <div className='btn-cat mt-4' style={{ direction: 'rtl' }}>
                    {
                        Catgories.map((category, i) => {
                            return (
                                <button type='button' style={{ backgroundColor: CatgorIndex === i ? 'rgb(0, 181, 152)' : 'rgb(0, 28,66 )' }} className='btnCatgory text-white me-1 ms-1' key={category} onClick={() => setIndex(i)} >
                                    {category}
                                </button>
                            )
                        })
                    }
                </div>
                <hr className='hr'></hr>
                
                <div  className='p-1 d-lg-flex justify-content-center'>
                    <div  className=' bg-white col-lg-10 brod shadow '>
                        <div className=' pb-3'>
                            {CatgorIndex === 0 ? <ReturnToMas zaki="זכאי" /> :
                                CatgorIndex === 1 ? <ReturnToMas zaki="דורש בדיקה" /> :
                                    CatgorIndex === 3 ? <ReturnToMas zaki="שבח" /> :
                                        CatgorIndex === 2 ? <CallBack /> :
                                            <AddCus />}
                        </div>
                    </div>
                </div>

                <br />

            </div>

        </div>

    )
}





