import React, { useEffect, useRef, useState } from 'react'
import { RiArrowRightDoubleFill } from "react-icons/ri";
import { getDatabase, ref as databaseRef } from 'firebase/database';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';

export default function Ask(props) {
    const [ansQesList, setansQes] = useState([]);
    const [ageChild, setAgeChild] = useState(["0 - 1", "1 - 5", "6 - 12", "13 - 17"]);
    const [countQes, setCount] = useState(0);
    const [selectedOptionC, setSelectedOptionC] = useState('');
    const [selectedOptionCAge0, setSelectedOptionCAgeZ] = useState(0);
    const [selectedOptionCAge5, setSelectedOptionCAgeF] = useState(0);
    const [selectedOptionCAge12, setSelectedOptionCAgeT] = useState(0);
    const [selectedOptionCAge17, setSelectedOptionCAgeS] = useState(0);
    const [selectedOptionF, setSelectedOptionF] = useState('');
    const [uploadID, setid] = useState(false);
    const [chekTow, setChek] = useState('null');
    const [isVisibleErrKode, setErrKode] = useState(false);
    const [isVisibleErrChild, setErrChild] = useState(false);
    const [isOpen, setIsOpen] = useState(false)
    const [showInputBackMas, setSHowBackMas] = useState(false);
    const [showLife, setSLife] = useState(false);
    const [loading, setLoad] = useState(false);
    const [countChild, setCountChild] = useState(0)
    const [TromaUrl, setTromaUrl] = useState('');
    const [lumiUrl, setlumiaUrl] = useState('');
    const [selectedFileTroma, setSelectedFileTroma] = useState("");
    const [selectedFilelumi, setSelectedFilelumi] = useState("");
    const [sumTromaValue, setSumTromaValue] = useState("");
    const male = "זכר"
    const woman = "נקבה"
    const yes = "כן"
    const no = "לא"
    let countChilds = 0;
    const sumTroma = useRef();
    const sumBackLaumi = useRef();
    const sumLife = useRef();
    const sumMas = useRef();
    const storage = getStorage();
    useEffect(() => {
        if (props.ifMrrey) {
            setCount(12);
        }
        const data = localStorage.getItem('timestamp2');
        console.log("ko " , props.checkTowTest);
        // if(props.ifMrrey){
        //     setCount(2);
        // }
        setChek(data);
        const ansQesList = [
            "--",
            "--",
            "--",
            "--",
            "--",
            "--",
            "--",
            "--",
            "--",
            "--",
            "--",
            "--",
            "--",
        ]
        setansQes(ansQesList);
    }, [])


    const ansQes = [
        { qes: "זכר או נקבה ?", ans: "--" },
        { qes: "מצב משפחתי", ans: "--" },
        { qes: "האם יש לך ילדים?", ans: "--" },
        { qes: "כמה?", ans: "--" },
        { qes: "כמה ילדים יש לך מתחת לגיל שנה?", ans: "--" },
        { qes: "כמה ילדים יש לך מתחת לגיל 6?", ans: "--" },
        { qes: "כמה ילדים יש לך מתחת לגיל 13?", ans: "--" },
        { qes: "כמה ילדים יש לך מתחת לגיל 18?", ans: "--" },
        { qes: "קיים ילד עם נטולי יכולת?", ans: "--" },
        { qes: "האם יש לך תואר אקדמי?", ans: "--" },
        { qes: "תרומות?", ans: "--" },
        { qes: "סכום תרומות", ans: "--" },
        { qes: "קיים ביטוח חיים?", ans: "--" },
        { qes: "אישור תושב ישוב מזכה?", ans: "--" },
        { qes: "הכנסות מביטוח לאומי? (מילואים,אבטלה,אמהות)", ans: "--" },
        { qes: "אישור משיכת קופת גמל? (טופס 806)", ans: "--" },
        { qes: "אישור ניכוי מס? (טופס 867)", ans: "--" },
    ]

    const handleSelectFamily = (event) => {
        setSelectedOptionF(event.target.value)
        ansQes[countQes].ans = event.target.value;
        saveData(1, event.target.value);
        setCount(countQes + 1);
    };
    const handleSelectChild = (event) => {
        setSelectedOptionC(event.target.value)
        ansQes[countQes].ans = event.target.value;
        saveData(3, event.target.value);
        setCount(countQes + 1);
    };

    const handleSelectChildAge = (event, age) => {
        if (age == "0") {
            countChilds = parseInt(event.target.value) + parseInt(selectedOptionCAge5) + parseInt(selectedOptionCAge12) + parseInt(selectedOptionCAge17);
            setSelectedOptionCAgeZ(event.target.value)
            ansQes[4].ans = event.target.value;
            saveData(4, event.target.value);
        }
        if (age == "5") {
            countChilds = parseInt(selectedOptionCAge0) + parseInt(event.target.value) + parseInt(selectedOptionCAge12) + parseInt(selectedOptionCAge17);
            setSelectedOptionCAgeF(event.target.value)
            ansQes[5].ans = event.target.value;
            saveData(5, event.target.value);
        }
        if (age == "12") {
            countChilds = parseInt(selectedOptionCAge0) + parseInt(selectedOptionCAge5) + parseInt(event.target.value) + parseInt(selectedOptionCAge17);
            setSelectedOptionCAgeT(event.target.value)
            ansQes[6].ans = event.target.value;
            saveData(6, event.target.value);
        }
        if (age == "17") {
            countChilds = parseInt(selectedOptionCAge0) + parseInt(selectedOptionCAge5) + parseInt(selectedOptionCAge12) + parseInt(event.target.value);
            setSelectedOptionCAgeS(event.target.value)
            ansQes[7].ans = event.target.value;
            saveData(7, event.target.value);
        }
        if (countChilds > ansQesList[3]) {
            setErrChild(true);
            setTimeout(() => {
                setErrChild(false);
            }, 3000);
        }
        if (countChilds == ansQesList[3]) {
            setCount(8);
        }
    }


    const listQes = [
        "זכר או נקבה ?",
        "מצב משפחתי",
        "האם יש לך ילדים?",
        "כמה?",
        "כמה ילדים יש לך מתחת לגיל שנה?",
        "כמה ילדים יש לך מתחת לגיל 6?",
        "כמה ילדים יש לך מתחת לגיל 13?",
        "כמה ילדים יש לך מתחת לגיל 18?",
        "קיים ילד עם נטולי יכולת?",
        "האם יש לך תואר אקדמי?",
        "תרומות?",
        "סכום תרומות",
        "קיים ביטוח חיים?",
        "אישור תושב ישוב מזכה?",
        "הכנסות מביטוח לאומי? (מילואים,אבטלה,אמהות)",
        "אישור משיכת קופת גמל? (טופס 806)",
        "אישור ניכוי מס? (טופס 867)",

    ];

    const saveData = (num, ans) => {
        const updatedItems = [...ansQesList];
        // Update the item at the specified index
        if (props.ifMrrey) {
            if (num == 12) {
                if (ans == "כן") {
                    setSLife(true);
                } else {
                    if (ans == "") {
                        updatedItems[num] = "כן";
                        props.updateAnsForMerry(num, updatedItems, TromaUrl)
                        setCount(14);
                        setSLife(false);
                    } else {
                        updatedItems[num] = ans;
                        props.updateAnsForMerry(num, updatedItems)
                        setCount(14);
                        setSLife(false);
                    }

                }

            }
            if (num == 14) {
                if (ans != "כן" && ans != "לא" && ans != "|") {
                    updatedItems[num] = ans;
                    props.updateAnsForMerry(num, updatedItems)
                } else {
                    updatedItems[num] = ans == "לא" ? "לא" : "כן";
                    props.updateAnsForMerry(num, updatedItems)
                }
            }
            if (num == 15) {
                updatedItems[num] = ans;
                props.updateAnsForMerry(num, updatedItems)
            }

        } else {
            updatedItems[num] = ans;
        }
        if (num == 1) {
            updatedItems[num] = ans;
        }
        if (num == 3) {
            updatedItems[num] = ans;
        }
        setansQes(updatedItems)
        if (num === 10) {
            if (ans === "כן") {
                setCount(11)
            } else {
                setCount(12)
            }
        }

        if (num === 2) {
            if (ans === "כן") {
                setCount(3)
            } else {
                setCount(9)
            }
        }
        else {
            if (num != 10) {
                if (num === 11) {
                    if (ans === "") {
                        setErrKode(true);
                        setTimeout(() => {
                            setErrKode(false);
                        }, 3000);
                    } else {
                        setCount(num + 1);
                    }
                } else {
                    if (countQes === 12) {
                        if (!props.ifMrrey) {
                            if (ans == "כן") {
                                setSLife(true);
                            } else {
                                if (ans == "") {
                                    updatedItems[num] = "כן";
                                    setansQes(updatedItems)
                                }
                                setCount(num + 1);
                                setSLife(false);
                            }
                        }
                    } else {
                        if (countQes === 16) {
                            setCount(17);
                            setid(true);
                            props.upadteAnsQes(updatedItems ,TromaUrl,lumiUrl );
                            if (chekTow !== "null") {
                                props.upadteAnsQes(updatedItems ,TromaUrl,lumiUrl);
                            }
                        }
                        else {
                            if (num == 14 && ans == "כן") {
                                setSHowBackMas(true);
                            } else {
                                if (num == 14) {
                                    if (ans == "|") {
                                        updatedItems[num] = "כן";
                                        setansQes(updatedItems)
                                    }
                                    setSHowBackMas(false);
                                    setCount(num + 1);
                                } else {
                                    if (num != 4 && num != 5 && num != 6 && num != 7 && num != 12) {
                                        setCount(num + 1);
                                    }
                                }

                            }

                        }

                    }
                }
            }
        }
    }

    const backQnsOrPage = () => {
        // if(props.ifMrrey && countQes == 1 ){
        //     props.bakePage(2)
        // }
        if (countQes > 0) {
            setCountChild(0);
            setSelectedOptionCAgeZ(0)
            setSelectedOptionCAgeF(0)
            setSelectedOptionCAgeT(0)
            setSelectedOptionCAgeS(0)
            if (countQes === 9) {
                if (ansQesList[2] === "לא") {
                    setCount(2)
                }
                else {
                    setCount(countQes => countQes - 1)
                }
            } else {

                if (countQes === 12) {

                    setSLife(false);
                    if (ansQesList[10] === "לא") {
                        setCount(10)
                    } else {
                        setCount(11)
                    }

                } else {
                    if (countQes === 14) {
                        setSHowBackMas(false);
                        if (!props.ifMrrey) {
                            setCount(countQes => countQes - 1)
                        } else {
                            setCount(12)
                        }
                    }
                    else {
                        if (countQes === 8) {
                            setCount(2)
                            setCountChild(0);
                        } else {
                            setCount(countQes => countQes - 1)
                        }
                    }
                }
            }
            if (uploadID) {
                setCount(15);
                setid(false);
            }
            setSelectedOptionF('');
            setSelectedOptionC('');
        } else {
            props.bakePage(2)
        }
    }

    const toggleBox = () => {
        setIsOpen(!isOpen);
    };

    const handleImageChange = (file, funUpdte) => {
        if (file) {
            setLoad(true);
            if (ref(storage, `images/${file.name}`)) {
            }
            const storageRef = ref(storage, `images/${file.name}`);
            uploadBytes(storageRef, file).then((snapshot) => {
                getDownloadURL(snapshot.ref).then((downloadURL) => {
                    funUpdte(downloadURL);
                    setLoad(false);
                    setSelectedFileTroma("")
                    setSelectedFilelumi("")
                    const database = getDatabase();
                });
            }).catch((error) => {
                console.error('Error uploading image: ', error);
            }).catch((error) => {
                console.error('Error uploading image:2 ', error);
            });
        }

    };


    const handleFileChangeTroma = (event) => {
        setSelectedFileTroma(event.target.files[0]);
        handleImageChange(event.target.files[0], setTromaUrl)
    };
    const handleFileChangeLumi = (event) => {
        setSelectedFilelumi(event.target.files[0]);
        handleImageChange(event.target.files[0], setlumiaUrl)
    };


    return (
        <div>
            <div id="" className="content">
                {
                    <div className="form-group  titlecheck">
                        <label className='myText' style={{ fontWeight: '', fontSize: '25px' }}> {countQes == 4 || countQes == 5 || countQes == 6 || countQes == 7 ? "סמן כמה ילדים יש לך מכל גיל" : listQes[countQes]}</label>
                    </div>
                }
                <div  className=''>
                    {(countQes == 3) && <select className=' pe-3 ps-3 mt-2 select p-2' value={selectedOptionC} onChange={handleSelectChild}>
                        <option value={selectedOptionC} className=''>בחר</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6+</option>
                    </select>}

                    <div className=''>
                        {(countQes == 4 || countQes == 5 || countQes == 6 || countQes == 7) &&
                            <div className='d-xl-flex justify-content-xl-center  mt-2'>
                                <div className='  d-flex  justify-content-center  mt-2'>
                                    <div>
                                        <div className=' titlecheck mt-2'>{ageChild[0]}</div>
                                        <select
                                            className='pe-3 ps-3 mt-2 select p-2'
                                            value={selectedOptionCAge0}
                                            onChange={(e) => handleSelectChildAge(e, "0")}>
                                            <option value="0">0</option>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5">5</option>
                                            <option value="6+">6+</option>
                                        </select>
                                    </div>
                                    <div>
                                        <div className=' titlecheck mt-2'>{ageChild[1]}</div>
                                        <select className='me-3 pe-3 ps-3 mt-2 select p-2' value={selectedOptionCAge5} onChange={(e) => handleSelectChildAge(e, "5")}>
                                            <option value="0">0</option>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5">5</option>
                                            <option value="6+">6+</option>
                                        </select>
                                    </div>
                                </div>
                                <div className=' d-flex justify-content-center ms-3 mt-2'>
                                    <div>
                                        <div className='titlecheck mt-2'>{ageChild[2]}</div>
                                        <select className='me-3 pe-3 ps-3 mt-2 select p-2' value={selectedOptionCAge12} onChange={(e) => handleSelectChildAge(e, "12")}>
                                            <option value="0">0</option>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5">5</option>
                                            <option value="6+">6+</option>
                                        </select>
                                    </div>
                                    <div>
                                        <div className=' titlecheck mt-2'>{ageChild[3]}</div>
                                        <select className='me-3 pe-3 ps-3 mt-2 select p-2' value={selectedOptionCAge17} onChange={(e) => handleSelectChildAge(e, "17")}>
                                            <option value="0">0</option>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5">5</option>
                                            <option value="6+">6+</option>
                                        </select>
                                    </div>
                                </div>


                            </div>
                        }

                    </div>
                    {countQes == 1 && <select className=' pe-3 ps-3 mt-2 select p-2' value={selectedOptionF} onChange={handleSelectFamily}>
                        <option value="" className=''>בחר</option>
                        <option value="רווק">רווק</option>
                        <option value="נשוי">נשוי</option>
                        <option value="אלמן">אלמן</option>
                        <option value="גרוש">גרוש</option>
                        <option value="פרוד">פרוד</option>
                    </select>}
                    {
                        countQes == 11 && <div>
                            <input ref={sumTroma} pattern="[0-9]*" className=' form-control mt-2' placeholder='הכנס סכום תרומות'></input>
                            <h6 className='titlecheck mt-2'>ניתן לעלות טופס תרומות במידה וקיים</h6>
                            <div className=''>
                                <div>
                                    <input id="file" type="file" onChange={handleFileChangeTroma} />
                                    <label htmlFor="file" className=" text-danger bg-white p-1  brod mt-2">
                                        בחר טופס
                                    </label>
                                </div>
                                <div>
                                    {
                                        TromaUrl != "" && <a target="_blank" href={TromaUrl}>הצג</a>
                                    }
                                </div>
                            </div>
                        </div>
                    }
                    {(selectedFileTroma !="") &&
                        <div className="charging-circle-container">
                            <div className="charging-circle"></div>
                        </div>
                    }
                    {
                        showInputBackMas && <div>
                            <h6 className=' text-danger  bg-white brod p-1'>* אם אינך יודע את הנתונים, נתן להמשיך בתהליך</h6>
                            <input ref={sumBackLaumi} pattern="[0-9]*" className=' form-control mt-2' placeholder=' הכנס סכום שקבלת'></input>
                            <input ref={sumMas} pattern="[0-9]*" className=' form-control mt-2' placeholder='הכנס סכום מס ששלמת'></input>
                            <h6 className=' titlecheck mt-2'>ניתן לעלות טופס מביטוח לאומי במידה וקיים</h6>
                            <div className=''>
                                <div>
                                    <input id="file" type="file" onChange={handleFileChangeLumi} />
                                    <label  htmlFor="file" className="text-danger">
                                        בחר טופס
                                    </label>
                                </div>
                                <div>
                                    {
                                        lumiUrl != "" && <a target="_blank" href={lumiUrl}>הצג</a>
                                    }
                                </div>
                            </div>
                        </div>
                    }
                    {(selectedFilelumi != "") &&
                        <div className="charging-circle-container">
                            <div className="charging-circle"></div>
                        </div>
                    }
                    {
                        showLife && <div>
                            <h6 className=' text-danger  bg-white brod  p-1'>* אם אינך יודע את הנתונים, נתן להמשיך בתהליך</h6>
                            <input ref={sumLife} pattern="[0-9]*" className=' form-control mt-2' placeholder='הכנס סכום'></input>
                        </div>
                    }
                    <div className=''>
                        {
                            (countQes != 11 && !showInputBackMas && !showLife) &&

                            <div className=' d-flex justify-content-center align-content-center'>
                                {
                                    countQes != 3 && countQes != 1 && countQes != 4 && countQes != 5 && countQes != 6 && countQes != 7 &&
                                    <button className="btnAsk p-2  text-white pe-4 ps-4 mt-3" onClick={countQes <= 16 ? () => saveData(countQes, countQes == 0 ? male : yes) : () => setid(true)}> {countQes == 0 ? male : yes}</button>
                                }
                                {
                                    countQes != 3 && countQes != 1 && countQes != 4 && countQes != 5 && countQes != 6 && countQes != 7 &&
                                    <button className="btnAsk p-2 text-white pe-4 ps-4 me-5 mt-3" onClick={countQes <= 16 ? () => saveData(countQes, countQes == 0 ? woman : no) : () => setid(true)} >{countQes == 0 ? woman : no}</button>
                                }
                            </div>
                        }
                        {
                            (countQes == 11) &&

                            <div className=' d-flex justify-content-center align-content-center'>
                                <button className="btnNext pe-3 ps-3  pe-4 ps-4 mt-4" onClick={() => saveData(countQes, sumTroma.current.value)} >המשך</button>
                            </div>
                        }
                        {
                            (showInputBackMas) &&

                            <div className=' d-flex justify-content-center align-content-center'>
                                <button className="btnNext pe-3 ps-3   pe-4 ps-4 mt-4" onClick={() => saveData(countQes, sumBackLaumi.current.value + "|" + sumMas.current.value)} >המשך</button>
                            </div>
                        }
                        {
                            (showLife) &&

                            <div className=' d-flex justify-content-center align-content-center'>
                                <button className="btnNext pe-3 ps-3   pe-4 ps-4 mt-4" onClick={() => saveData(countQes, sumLife.current.value)} >המשך</button>
                            </div>
                        }
                        {
                            <div className='titlecheck' style={{ display: isVisibleErrKode ? 'block' : 'none', color: 'withe', padding: '10px', fontSize: '20px', borderRadius: '5px' }}>
                                הכנס סכום תרומות
                            </div>
                        }
                        {
                            <div className='titlecheck' style={{ display: isVisibleErrChild ? 'block' : 'none', color: 'withe', padding: '10px', fontSize: '20px', borderRadius: '5px' }}>
                                מספר הילדים גדול יותר
                            </div>
                        }

                        <div className=' d-flex justify-content-between align-content-between'>
                            <button onClick={backQnsOrPage} style={{ fontSize: '18px' }} className=" btnNext pe-3 ps-3 mt-3 border-3"> <RiArrowRightDoubleFill size={30} />
                            </button>

                        </div>



                        {/* <button className="btn btn-primary mt-3" onClick={countQes > 0 ? BackQes : BackPageReashi}>חזור</button> */}
                        {
                            // (nextAfterID && countQes == 12) && 
                            // <button className='btn btn-primary mt-3' onClick={nextPage} >המשך</button>
                        }

                    </div>
                </div>
            </div>
        </div>
    )
}
