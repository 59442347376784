import React, { useEffect, useState } from 'react';
import '../step.css'
import { getDatabase, ref, get, child } from 'firebase/database';
import { useParams, useNavigate } from 'react-router-dom';
import ShowLidim from '../menger/ShowLidim';
import '../loading.css';
import { FiLogIn } from "react-icons/fi";


export default function LoginRashi(props) {
    const [Nameuser, setNameUser] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [showComponent, setShowComponent] = useState(false);
    const [loading, setLoad] = useState(false);
    const [Mess, setMess] = useState('');
    const [MessShow, setShowMess] = useState(false);
    const { name } = useParams();
    const nav = useNavigate();


    const handleLogin = async (e) => {
        setLoad(true)
        e.preventDefault(); // Prevent default form submission behavior
        const dbRef = ref(getDatabase());
        try {
            setLoad(true);
            const userSnapshot = await get(child(dbRef, `ron_mas/${Nameuser}`));
    
            if (!userSnapshot.exists() || userSnapshot.child("userWork").val() !== "on") {
                setLoad(false);
                setShowMess(true);
                setMess("בדוק שהשם משתמש הוקלד נכון או שחשבון לא פעיל");
                setTimeout(() => setShowMess(false), 3000);
                return;
            }
    
            const passwordDevice = userSnapshot.child("details/password").val();
            if (passwordDevice === password) {
                nav(`/${Nameuser}`);
                localStorage.setItem("userLogin", Nameuser);
                localStorage.setItem("passwordUserLogin", password);
            } else {
                setLoad(false);
                setShowMess(true);
                setMess("הסיסמה אינה נכונה");
                setTimeout(() => setShowMess(false), 3000);
            }
        } catch (error) {
            setLoad(false);
            console.error(error);
        }
        // get(child(dbRef, `ron_mas/`)).then((snapshot) => {
        //     if (snapshot.hasChild(Nameuser)) {
        //         get(child(dbRef, `ron_mas/${Nameuser}/userWork`)).then((snapshot) => {
        //             if (snapshot.val() == "on") {
        //                 get(child(dbRef, `ron_mas/${Nameuser}/details/password`)).then((snapshot) => {
        //                     if (snapshot.val() == password) {
        //                         nav(`/${Nameuser}`)
        //                         setLoad(false)
        //                         localStorage.setItem(`userLogin`, Nameuser)
        //                         localStorage.setItem(`passwordUserLogin`, password)
        //                     } else {
        //                         setLoad(false)
        //                         setShowMess(true)
        //                         setMess("הסיסמה אינה נכונה")
        //                         setTimeout(() => {
        //                             setShowMess(false);
        //                         }, 3000);
        //                     }
        //                 })
        //             }
        //         }).catch((error) => {
        //             setLoad(false)
        //             console.error(error);
        //         });
        //     } else {
        //         setLoad(false)
        //         setShowMess(true)
        //         setMess("בדוק שהשם משתמש הוקלד נכון")
        //         setTimeout(() => {
        //             setShowMess(false);
        //         }, 3000);
        //     }
        // }).catch((error) => {
        //     setLoad(false)
        //     console.error(error);
        // });

    };

    useEffect(() => {
        localStorage.setItem("ifZaki", false);
        if (localStorage.getItem(`userLogin`) != null && localStorage.getItem(`passwordUserLogin`) != null) {
            setNameUser(localStorage.getItem(`userLogin`));
            setPassword(localStorage.getItem(`passwordUserLogin`));
        }
    }, [])

    const callCompny = () => {
        window.location = `tel:0542999928`;
    }

    const nextPageLog = () => {
        nav(`/${name}/`)
    }

    return (
        <div>
            {!showComponent ? (
                <div className='bodyl anBack'>
                    <div className=" bg-white d-flex justify-content-between ">
                        <div className='position-relative  ps-2 mt-2'>
                            <img
                                style={{ transform: 'scaleX(-1)' }}
                                className='animated-button '
                                src={`${process.env.PUBLIC_URL}/imges/namelogot.png`}
                                alt="Name Logo"
                                height={40}
                            />
                        </div>
                  
                    </div>
                    <div className='position-relative  d-lg-flex justify-content-lg-center p-3 mt-3'>
                        <div className='backimageBoxLogin  col-lg-8  shadow '>
                            <div className='container d-flex justify-content-center align-items-center  ' style={{ direction: 'rtl' }}>
                                <div className='container d-flex justify-content-center align-items-center ' style={{ direction: 'rtl' }}>

                                    <div className=" col-11 col-xl-5 mt-xl-4 p-3 mt-4 ">
                                        <h2 className='textLog animated-b text-white mb-3'>התחברות</h2>
                                        <form onSubmit={handleLogin}>
                                            <div className="form-group an">
                                                <input
                                                    type='sumbit'
                                                    className="form-control"
                                                    placeholder="שם משתמש"
                                                    value={Nameuser}
                                                    onChange={(e) => setNameUser(e.target.value)}
                                                />
                                            </div>
                                            <div className="form-group mt-4 an">
                                                <input
                                                    type="password"
                                                    className="form-control"
                                                    placeholder="סיסמה"
                                                    value={password}
                                                    onChange={(e) => setPassword(e.target.value)}
                                                />
                                            </div>
                                            {error && <div className="alert alert-danger">{error}</div>}
                                            <button type="submit" className="animated-button pe-2 ps-2 p-2 mt-4  pe-5 ps-5 btnCall">כניסה</button>
                                        </form>
                                        {MessShow &&
                                            <div style={{ color: 'white', padding: '10px', fontSize: '18px', borderRadius: '5px' }}>
                                                * {Mess}
                                            </div>
                                        }
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="me-3 mt-3 d-flex  justify-content-center align-baseline fixed-bottom p-3 an">
                        <button onClick={() => callCompny()} style={{ fontWeight: 'bolder', fontSize: '18px' }} className='pe-3 ps-3 mt-3 btnCall border-3'>
                            צור קשר
                            {/* צור קשר <MdContactPhone className='mb-1  align-bottom' size={23} /> */}
                        </button>
                    </div >
                    {loading &&
                        <div className="charging-circle-container">
                            <div className="charging-circle"></div>
                        </div>
                    }
                    {/* <div className=" me-3 mt-3 d-flex  justify-content-center align-baseline text-w fixed-bottom p-3 an">
                        <button  onClick={() => callCompny()}  style={{ fontWeight: 'bolder', fontSize: '23px' }} className='btn btn-dark pe-2 ps-2 mt-3 border-3 border-success'>
                            צור קשר <MdContactPhone className='mb-1  align-bottom' size={23} />
                        </button>
                    </div > */}
                </div>
            ) : (
                <div>
                    <ShowLidim />
                </div>
            )}

        </div>


    );
};
