import React, { useEffect, useRef, useState } from 'react'
import 'firebase/compat/database';
import './pageCss.css'
import { MdOutlineGppGood } from "react-icons/md";
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import ScrollAnimation from '../ScrollAnimation';
import { GrStatusGood } from "react-icons/gr";
import ScrollAnimationWinner from '../ScrollAnimationWinner';
import { getDatabase, ref as refData, set } from "firebase/database";
import emailjs from '@emailjs/browser';

const divStyle = {
    backgroundSize: 'contain',
    height: " 300px", /* Set a fixed or dynamic height */
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center', // Center the background image horizontally
};


export default function MainPage() {
    const sectionRef = useRef(null);
    const sectionRefWork = useRef(null);
    const sectionRefSef = useRef(null);
    const sectionRefApp = useRef(null);
    const sectionRefWinner = useRef(null);
    const sectionRefAddUser = useRef(null);
    const [listImg, setImg] = useState([])
    const [isOpen, setIsOpen] = useState(false)
    const [ShowKode, setShowKode] = useState(false);
    const [ShowMess, setShowMess] = useState(false);
    const [isVisibleErrKode, setErrKode] = useState(false);
    const [nameData, setNameData] = useState();
    const [phoneData, setPhoneData] = useState();
    const [myKode, setNumbers] = useState();
    const inputName = useRef();
    const inputPhone = useRef();
    const kodeCus = useRef();

    const { ref, inView } = useInView({
        threshold: 0.1, // Adjust to trigger the animation sooner or later
    });
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };
        const myListDataTamp = [];
        for (let i = 6; i < 10; i++) {
            myListDataTamp.push({
                url: `/imges/${i}.png`
            })
        }
        console.log("koko", myListDataTamp);
        setImg(myListDataTamp);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);

    }, [])

    const scrollToSection = (id) => {
        id.current.scrollIntoView({ behavior: 'smooth' });
    };

    const checkKodeData = (name, phone) => {
        if (name != "" && phone != "") {
            setNameData(name);
            setPhoneData(phone);
            setIsOpen(false);
            const generatedNumbers = [];
            for (let i = 0; i < 4; i++) {
                generatedNumbers.push(Math.floor(Math.random() * 10)); // Generates random numbers from 0 to 9
            }
            handleSendSMS(generatedNumbers.join(''), phone)
            setNumbers(generatedNumbers.join(''))
            setShowKode(true);
        }
    }

    const checkode = (kode) => {
        //myKode
        if (myKode === kode) {
            setShowKode(false);
            uploadFireBaseBack();
        } else {
            setErrKode(true);
            setTimeout(() => {
                setErrKode(false);
            }, 3000);
        }
    }

    const handleSendSMS = async (kode, phone) => {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", "App 7fdce878366e700fe8ba5d5b60aeff9c-0ac33972-88a1-4258-9250-31ba1635ac9b");
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Accept", "application/json");

        const raw = JSON.stringify({

            "messages": [
                {
                    "destinations": [{ "to": `972${phone}` }],
                    "from": "touchpay",
                    "text": `קוד האימות שלך הוא: ${kode} `
                }
            ]
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };

        fetch("https://rgdmem.api.infobip.com/sms/2/text/advanced", requestOptions)
            .then((response) => response.text())
            .then((result) => console.log(result))
            .catch((error) => console.error(error));
    };

    //send email new lid
    const handleSubmit = (mess, emailMenger) => {
        const emailData = {
            title: "נכנס ליד חדש",
            user_name: "wdwd",   // Adjust based on your template
            email: emailMenger,
            message: mess,
        };

        emailjs
            .send('service_acpprcc', 'template_wg9rtab', emailData, 'f4llL0BnWHSNZumff')
            .then((response) => {
                console.log('Email sent successfully!', response.status, response.text);
                // Optionally reset the form or show a success message
            })
            .catch((err) => {
                console.error('Failed to send email. Error:', err);
            });
    };


    const sendLidMEng = (mess, number) => {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", "App 7fdce878366e700fe8ba5d5b60aeff9c-0ac33972-88a1-4258-9250-31ba1635ac9b");
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Accept", "application/json");

        const raw = JSON.stringify({
            "messages": [
                {
                    "destinations": [{ "to": `972${number}` }],
                    "from": "touchpay",
                    "text": mess
                },
            ]
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };
        fetch("https://rgdmem.api.infobip.com/sms/2/text/advanced", requestOptions)
            .then((response) => response.text())
            .then((result) => console.log(result))
            .catch((error) => console.error(error));
    }

    const uploadFireBaseBack = () => {
        const db = getDatabase();
        const timestamp = Date.now(); // Generating timestamp
        console.log("kok", nameData, phoneData)
        set(refData(db, "ron_mas/" + "ron/" + "bTowb/" + timestamp), {
            'name': nameData,
            'phone': phoneData,
            'isChecked': false,
            'date': new Date().toLocaleDateString('he-IL') + " | " + (new Date().getHours() + ":" + (new Date().getMinutes() < 10 ? "0" + new Date().getMinutes() : new Date().getMinutes())),
        }).then(() => {
            setShowMess(true);
            //handleSubmit(`לקוח חדש השאיר פרטים להצטרפות למערכת. \n${nameData} ${phoneData}`, "office.touchpay@gmail.com")
            //sendLidMEng(`לקוח חדש השאיר פרטים להצטרפות למערכת. \n${nameData} ${phoneData}`, "0542999928");
        }).then(() => {
            //handleSubmit(`לקוח חדש השאיר פרטים להצטרפות למערכת. \n${nameData} ${phoneData}`, "rontal140599@gmail.com")
            //sendLidMEng(`לקוח חדש השאיר פרטים להצטרפות למערכת. \n${nameData} ${phoneData}`, "0549103260");
        });
    }

    const outKode = () => {
        setShowKode(false);
        setShowMess(false);
    }

    const toggleBox = () => {
        setIsOpen(!isOpen);
    };
    return (
        <div className=' anBack'>
            <div className=' container-fluid bg-white'>
                <div className="d-flex justify-content-between  container ">
                    <div className='position-relative mt-2 '>
                        <img
                            className='animated-button '
                            src={`${process.env.PUBLIC_URL}/imges/namelogot.png`}
                            height={40}
                        />
                    </div>
                    {
                        !isMobile && <div className=' mt-2 '>
                            <button onClick={() => scrollToSection(sectionRefWinner)} style={{ direction: 'rtl' }} className='btnCall me-3'> המיוחד בשירות</button>
                            <button onClick={() => scrollToSection(sectionRefSef)} className='btnCall me-3'>האבטחה שלנו</button>
                            <button onClick={() => scrollToSection(sectionRefApp)} style={{ direction: 'rtl' }} className='btnCall me-3'> האפלקציה שלנו</button>
                            <button onClick={() => scrollToSection(sectionRefWork)} style={{ direction: 'rtl' }} className='btnCall me-3'>איך זה עובד ? </button>
                            <button onClick={() => scrollToSection(sectionRef)} style={{ direction: 'rtl' }} className='btnCall me-3'>מי אנחנו ?</button>
                        </div>
                    }

                </div>
            </div>
            {/* מי אנחנו */}
            <div ref={sectionRef} style={{ direction: "ltr", fontSize: "40px" }} className="  d-xl-flex  justify-content-xl-between">
                <div className=' mt-lg-4 '>
                    <h1 className='titleFont pe-1 animated-b'>
                        מי אנחנו ?
                    </h1>
                    <div className='  d-lg-flex justify-content-lg-end  pe-2'>
                        <h6 className='textPage col-lg-10 me-lg-3 ms-2 me-1  animated-button' style={{ direction: "rtl", fontSize: "22px" }}>
                            touchpay,היא פלטפורמה שמאפשרת לכל אחד לבצע בדיקה קלה ומהירה על מנת לבדוק אם זכאי להחזר מס מהמדינה ואתה כרואה חשבון מקבל מערכת שמנהלת לך את הלקוחות ועושה חישוב מדוייק של לקוחות קצה להחזרי מס וכל המידע נשמר אך ורק אצלנו במערכת.
                        </h6>
                    </div>
                    <h1 className=' titleFont animated-b pe-1 mt-3'>
                        המשימה שלנו פשוטה
                    </h1>
                    <h6 style={{ direction: "rtl", fontSize: "20px" }} className='textPage me-lg-3 me-1 ms-1 mt-3 pe-2 animated-button'>
                        <GrStatusGood color='blue' className='ms-lg-2 ms-1' />
                        המערכת שלנו סורקת את טפסי 106 ועושה חישוב אוטומטי.

                    </h6>
                    <h6 style={{ direction: "rtl", fontSize: "20px" }} className='textPage me-lg-3 me-1 ms-1 mt-3 pe-2 animated-button'>
                        <GrStatusGood color='blue' className='ms-lg-2 ms-1' />
                        תוצאה מדויקת בזמן מיידי.
                    </h6>
                    <h6 style={{ direction: "rtl", fontSize: "20px" }} className='textPage me-lg-3 me-1  ms-1 mt-3 pe-2 animated-button'>
                        <GrStatusGood color='blue' className='ms-lg-2 ms-1' />
                        המערכת שלנו חוסכת לכם זמן יקר בבדיקת הלקוח.
                    </h6>
                    <h6 style={{ direction: "rtl", fontSize: "20px" }} className=' textPage me-lg-3 me-1  ms-1 mt-3 pe-2 animated-button'>
                        <GrStatusGood color='blue' className='ms-lg-2 ms-1' />
                        מעקב וניהול לקוחות.
                    </h6>
                    <div className=" fixed-bottom d-flex justify-content-center mt-4 mt-lg-5 an">
                        <button
                            onClick={toggleBox}
                            style={{ fontWeight: 'bolder', fontSize: '17px' }}
                            className=" btn-add pt-lg-4 pb-lg-4 ps-5 text-center pe-5 pt-2 pb-2 ">
                            לחץ כאן להשארת פרטים להצטרפות
                        </button>
                    </div>
                </div>
                <div className='me-lg-2'>
                    <img
                        className='an imageShow mt-2 brod '
                        src={`${process.env.PUBLIC_URL}/imges/imageone.png`}
                    />
                </div>
            </div>


            {/* תהליך הרשמה */}

            <div ref={sectionRefWork} className=''>
                <ScrollAnimation />
                {
                    !isMobile ? <div className='d-flex ColorBack justify-content-center'>
                        <div className=''>
                            <img className='imageLevel' src="imges/leveltest.png" />
                        </div>
                    </div> : <div className='d-flex ColorBack justify-content-center'>
                        <div className=''>
                            <img className='imageLevel' src="imges/leveltestMobile.png" />
                        </div>
                    </div>
                }


                <div ref={sectionRefApp} className='d-flex justify-content-start '>
                    {
                        !isMobile &&
                        <div className="d-flex justify-content-between container">
                            <img
                                src={"/imges/9.png"}
                                style={{
                                    height: '100%',
                                    width: '300px',
                                }} />
                            <img
                                src={"/imges/7.png"}
                                style={{
                                    height: '100%',
                                    width: '300px',
                                }} />
                            <img
                                src={"/imges/8.png"}
                                style={{
                                    height: '100%',
                                    width: '300px',
                                }} />
                            <img
                                src={"/imges/6.png"}
                                style={{
                                    height: '100%',
                                    width: '300px',
                                }} />
                        </div>
                    }
                </div>
                <div className=''>
                    {
                        isMobile &&
                        <div className='d-flex justify-content-center'>
                            <div style={{ direction: "rtl" }} className="slide-container col-11">
                                <Slide >
                                    {listImg.map((slideImage, index) => (
                                        <div className=' d-flex justify-content-center' key={index} style={{ ...divStyle, backgroundImage: `url('${listImg[index].url}` }}></div>
                                    ))}
                                </Slide>
                            </div>
                        </div>
                    }
                </div>

            </div>
            {/* <div ref={sectionRefAddUser} className='addUserBack broderUser mb-2'>
                <ScrollAnimationAddUser />
            </div> */}

            {/* אבטחה */}
            <div ref={sectionRefSef} className='d-lg-flex bg-white justify-content-lg-evenly '>
                <div className=' pe-2 pt-1 mt-lg-5'>
                    <motion.div
                        ref={ref}
                        initial={{ opacity: 0, y: 50 }}
                        animate={inView ? { opacity: 1, y: 0 } : {}}
                        transition={{ duration: 0.5 }}
                    >
                        <h1 className='titleFont'>
                            אבטחה ללא פשרות
                        </h1>
                        <h6 className='textPage container ps-5' style={{ direction: "rtl", fontSize: "23px" }}>
                            touchpay מאבטחת את המידע שלך בשרתי גוגל וכל מידע נשמר אוטומטי אצלך במערכת ולא מועבר לרשות המסים ולשום גוף אחר !
                        </h6>
                        <h6 style={{ direction: "rtl", fontSize: "20px" }} className='textPage pe-2 mt-lg-5 mt-3'>
                            <MdOutlineGppGood color='blue' size={40} />
                            כל המידע נשמר באמצעות אבטחה Encryption in Transit.
                        </h6>
                        <h6 style={{ direction: "rtl", fontSize: "20px" }} className='textPage pe-2 mt-3'>
                            <MdOutlineGppGood color='blue' size={40} />
                            המידע נשמר במערכת שלך.
                        </h6>
                        <h6 style={{ direction: "rtl", fontSize: "20px" }} className='textPage pe-2 mt-3'>
                            <MdOutlineGppGood color='blue' size={40} />
                            אחסון המידע בשרתי google.
                        </h6>
                        <h6 style={{ direction: "rtl", fontSize: "20px" }} className='textPage pe-2 mt-3'>
                            <MdOutlineGppGood color='blue' size={40} />
                            תהליך אימות לכל לקוח.
                        </h6>
                    </motion.div>
                </div>
                <div>
                    <img
                        className='animated-button imageMonney '
                        src={`${process.env.PUBLIC_URL}/imges/securing.png`}
                    />
                </div>

            </div>

            {/* ניצחון */}
            <div ref={sectionRefWinner} className='d-lg-flex bg-light justify-content-lg-between '>
                <div className='pe-3 pt-1 mt-lg-1 ms-4'>
                    <ScrollAnimationWinner />
                </div>
                <div>
                    <img
                        className='animated-button imageWinner mb-5 mt-3 '
                        src={`${process.env.PUBLIC_URL}/imges/winner.png`}
                    />
                </div>
            </div>
            {/* <div className='addUserBack'>
                <ScrollAnimationAddUser />
            </div> */}

            {isOpen && (
                <div className="dialog-container " style={{ direction: 'rtl' }}>
                    <div className="dialog-content addUserBack">
                        <button style={{ background: 'none', border: 'none', fontWeight: 'bold', fontSize: '20px' }} className='text-danger' onClick={toggleBox}>X</button>
                        <div className='d-lg-flex justify-content-lg-center'>
                            <div className=''>
                                <h4 className=' text-white p-2 text-center'>פרטיים אישיים</h4>
                                <div className=' pe-3 ps-3'>
                                    <input ref={inputName} style={{ direction: "rtl" }} className=' form-control mt-2' placeholder='שם מלא'></input>
                                </div>
                                <div className=' pe-3 ps-3 mt-3'>
                                    <input ref={inputPhone} style={{ direction: "rtl" }} pattern="[0-9]*" type='number' className=' form-control' placeholder='מספר טלפון'></input>
                                </div>
                                <h6 className=' text-white mt-3 text-center'>אחד מנציגנו יחזור אלייך בדקות הקרובות.</h6>
                                <div className=' d-flex justify-content-center mt-4'>
                                    <button style={{ direction: 'rtl', fontSize: "25px" }} onClick={() => checkKodeData(inputName.current.value, inputPhone.current.value)} className='btnAddUser mb-2'>הצטרף אלינו</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {
                ShowKode && (
                    <div className="dialog-container  state-back" style={{ direction: 'rtl' }}>
                        <div className="dialog-content addUserBack m-3">
                            <button style={{ background: 'none', border: 'none', fontWeight: 'bold', fontSize: '20px' }} className=' d-flex text-danger' onClick={outKode}>X</button>
                            <h5 className=' text-center text-white'>רשום את קוד האימות שלך</h5>
                            <div className='  mt-2'>
                                <div className=' ms-5 me-5'>
                                    <input pattern="[0-9]*" type='number' ref={kodeCus} className='form-control  mt-2 mt-3'></input>
                                </div>
                                <div className=" text-center">
                                    <button className="btnAddUser pe-3 ps-3  text-center  mt-3" onClick={() => checkode(kodeCus.current.value)}>בצע אימות</button>
                                </div>
                                <div className=' text-center' style={{ display: isVisibleErrKode ? 'block' : 'none', color: 'red', padding: '10px', fontSize: '20px', borderRadius: '5px' }}>
                                    הקוד שהוקלד שגוי
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
            {
                ShowMess && (
                    <div className="dialog-container  state-back" style={{ direction: 'rtl' }}>
                        <div className="dialog-content addUserBack m-3">
                            <button style={{ background: 'none', border: 'none', fontWeight: 'bold', fontSize: '20px' }} className=' d-flex text-danger' onClick={outKode}>X</button>
                            <h5 className=' text-center text-white'>תודה, בקרוב נצור איתך קשר.</h5>
                        </div>
                    </div>
                )
            }

        </div>
    )
}
