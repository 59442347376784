import React, { useEffect, useState } from 'react'
import '../home.css'
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { IoIosLogIn } from "react-icons/io";
import { MdContactPhone } from "react-icons/md";
import { useSpring, animated } from 'react-spring';
import '/node_modules/bootstrap/dist/css/bootstrap.min.css';
import { IoMdArrowBack } from "react-icons/io";
import { FiLogIn } from "react-icons/fi";

import axios from 'axios';
import { format } from 'date-fns';
import { getDatabase, ref, onValue, limitToLast, get, remove, update, child, query, orderByKey, onChildChanged, onChildRemoved, onChildAdded } from 'firebase/database';

const moment = require('moment');

export default function AppEnterPage(props) {
  const { dynamicSegment } = useParams();
  const [name1, setName] = useState(false);
  const [phone, setPhone] = useState(0);
  const [remainingSecond, setremainingSecond] = useState(0);
  const [showTimeMess, setshowTimeMess] = useState(false);
  const nav = useNavigate();

  const nextPageMas = () => {
    nav(`calculator/`)
  }

  const nextPage = () => {
    nav(`calculator/`)
    let lastCheckTime = moment(localStorage.getItem("lastCheckTime"));
    const timestampCheck = new Date()
    let nowTime = moment(timestampCheck.toString());
    if (localStorage.getItem("lastCheckTime") == null) {
      nav(`calculator/`)
    }
    if (nowTime.isAfter(lastCheckTime)) {
      localStorage.setItem('typeSelcet', "calculator");
      nav(`calculator/`)
    } else {
      const differenceInMs = lastCheckTime - nowTime;
      const differenceInSeconds = Math.floor(differenceInMs / 1000);
      const differenceInMinutes = Math.floor(differenceInSeconds / 60);
      if (differenceInMinutes > 0) {
        setremainingSecond(differenceInMinutes + " דקות")
        setshowTimeMess(true);
      }
      else {
        setremainingSecond(differenceInSeconds + " שניות")
        setshowTimeMess(true);
      }

    }

  }
  const nextPageShevah = () => {
    nav(`Shevah/`)
  }
  const nextPageLog = () => {
    nav(`login/`)
  }
  const callCompny = () => {
    window.location = `tel:${phone}`;
  }

  useEffect(() => {
    lisnDB(dynamicSegment);
    localStorage.setItem('showPage', "false");
    if (localStorage.getItem("nameCPN") == dynamicSegment) {
      setName(true);
    }
    localStorage.setItem('nameCPN', dynamicSegment);
    doApi();
    doApiList();
    doApiBack();
    doApiMenger();
    getPhoneFireBase()
    if(dynamicSegment == "ron"){
      doApiBtowB();
    }
  }, [remainingSecond]);


  const lisnDB = (dynamicSegment) => {
    const db = getDatabase();
    const dbRef = ref(db);
    const itemsRef = child(dbRef, `/ron_mas/${dynamicSegment}/userWork/`);
    // Listen for any changes to the data
    onValue(itemsRef, (snapshot) => {
      doApi()
    });
  };


  const doApi = () => {

    const dbRef = ref(getDatabase());
    get(child(dbRef, `ron_mas/`)).then((snapshot) => {
      if (snapshot.hasChild(dynamicSegment)) {
        get(child(dbRef, `ron_mas/${dynamicSegment}/userWork`)).then((snapshot) => {
          if (snapshot.val() == "on") {
            setName(true)
            localStorage.setItem('showPage', "true");

          } else {
            nav(`/Error404`);
            setName(false)
            localStorage.setItem('showPage', "false");
          }
        }).catch((error) => {
          console.error(error);
        });
      } else {
        nav(`/Error404`);
        setName(false)
        localStorage.setItem('showPage', "false");
      }
    }).catch((error) => {
      console.error(error);
    });
  };

  //רשימת אנשים זכאים 
  const doApiList = async () => {
    const db = getDatabase();
    const name = dynamicSegment;
    const myListData = [];
    let detailsTest1 = [];
    try {
      const dbRef = ref(db, `/ron_mas/${name}/trueMas/`);
      const snapshot = await get(dbRef);
      if (snapshot.exists()) {
        const firebaseData = snapshot.val();
        const dataArray = Object.entries(firebaseData);
        for (const [idKay, item] of dataArray) {
          const yearRef = ref(db, `/ron_mas/${name}/trueMas/${idKay}/year/`);
          const yearSnapshot = await get(yearRef);
          if (yearSnapshot.exists()) {
            const yearData = yearSnapshot.val();
            const yearArray = Object.entries(yearData);


            for (const [idKay2, itemYear] of Object.entries(yearData)) {
              const pdfRef = ref(db, `/ron_mas/${name}/trueMas/${idKay}/year/${idKay2}/pdf`);
              const pdfRefM = ref(db, `/ron_mas/${name}/trueMas/${idKay}/year/${idKay2}/pdfM`);
              const pdfSnapshot = await get(pdfRef);
              const pdfSnapshotM = await get(pdfRefM);
              if (pdfSnapshot.exists()) {
                const pdfData = pdfSnapshot.val();
                const pdfArray = Object.values(pdfData);
                if (pdfSnapshotM.exists()) {
                  const pdfDataM = pdfSnapshotM.val();
                  const pdfArrayM = Object.values(pdfDataM);
                  detailsTest1.push({
                    year: itemYear.year,
                    namem: itemYear.nameM,
                    sum: itemYear.sum,
                    sumM: itemYear.sumM,
                    sahclin: itemYear.sahclin,
                    sahclinM: itemYear.sahclinM,
                    ans: itemYear.ans,
                    tromaUrl: itemYear.TromaUrl == null ? "" : itemYear.TromaUrl,
                    lumiUrl: itemYear.lumUrl == null ? "" : itemYear.lumUrl,
                    lumiUrlM: itemYear.lumUrlM == null ? "" : itemYear.lumUrlM,
                    dataClac: itemYear.dataClac,
                    dataClacM: itemYear.dataClacM,
                    isChecked: false,
                    enterMesM: itemYear.enterMesM,
                    koptGemlM: itemYear.koptGemlM,
                    safeLifeM: itemYear.safeLifeM,
                    pdf: pdfArray,
                    pdfM: pdfArrayM
                  });
                }
                else {
                  detailsTest1.push({
                    year: itemYear.year,
                    sum: itemYear.sum,
                    sahclin: itemYear.sahclin,
                    ans: itemYear.ans,
                    tromaUrl: itemYear.TromaUrl == null ? "" : itemYear.TromaUrl,
                    lumiUrl: itemYear.lumUrl == null ? "" : itemYear.lumUrl,
                    lumiUrlM: itemYear.lumUrlM == null ? "" : itemYear.lumUrlM,
                    dataClac: itemYear.dataClac,
                    dataClacM: itemYear.dataClacM,
                    isChecked: false,
                    enterMesM: itemYear.enterMesM,
                    koptGemlM: itemYear.koptGemlM,
                    pdf: pdfArray
                  });
                }
              }
            }
            myListData.push({
              name: item.name,
              id: item.id,
              idkay: idKay,
              date: item.date,
              phone: item.phone,
              color: item.color,
              resPerson: item.resPerson,
              isChecked: false,
              details: detailsTest1
            });
            detailsTest1 = [];
          }
        }

        localStorage.removeItem(`myArray${name}`);
        localStorage.setItem(`myArray${name}`, JSON.stringify(myListData.reverse()));
      } else {
        localStorage.removeItem(`myArray${name}`);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  //השאירו פרטים
  const doApiBack = async () => {
    const db = getDatabase();
    const myListData = [];
    const name = dynamicSegment;
    const dbRef = ref(db, `ron_mas/${name}/back/`);
    const snapshot = await get(dbRef);
    if (snapshot.exists()) {
      const firebaseData = snapshot.val();
      const dataArray = Object.entries(firebaseData);
      for (const [idKay, item] of dataArray) {
        myListData.push({
          name: item.name,
          phone: item.phone,
          date: item.date,
          isChecked: item.isChecked,
          type: item.type,
          idKay: idKay
        });
      }
      localStorage.setItem(`myArrayBack${name}`, JSON.stringify(myListData.reverse()));
      //setloading(false)
      //setBack(myListData.reverse());
    } else {
      localStorage.removeItem(`myArrayBack${name}`);

      //setloading(false)
    }
  };
  //השאירו פרטים
  const doApiBtowB = async () => {
    const db = getDatabase();
    const myListData = [];
    const name = dynamicSegment;
    const dbRef = ref(db, `ron_mas/ron/bTowb/`);
    const snapshot = await get(dbRef);
    if (snapshot.exists()) {
      const firebaseData = snapshot.val();
      const dataArray = Object.entries(firebaseData);
      for (const [idKay, item] of dataArray) {
        myListData.push({
          name: item.name,
          phone: item.phone,
          date: item.date,
          isChecked: item.isChecked,
          type: item.type,
          idKay: idKay
        });
      }
      localStorage.setItem(`myArrayBackBtowB`, JSON.stringify(myListData.reverse()));
      //setloading(false)
      //setBack(myListData.reverse());
    } else {
      localStorage.removeItem(`myArrayBackBtowB`);
      //setloading(false)
    }
  };
  const getPhoneFireBase = async () => {
    const db = getDatabase();
    const dbRef = ref(db, `/ron_mas/${dynamicSegment}/details/phone`);
    const snapshot = await get(dbRef);
    if (snapshot.exists()) {
      const firebaseData = snapshot.val();
      setPhone(firebaseData)
    }
  };
  //רשימת מנהלים
  const doApiMenger = async () => {
    const db = getDatabase();
    const myListData = [];
    const name = dynamicSegment;
    const dbRef = ref(db, `ron_mas/`);
    const snapshot = await get(dbRef);
    if (snapshot.exists()) {
      const firebaseData = snapshot.val();
      const dataArray = Object.entries(firebaseData);
      for (const [idKay, item] of dataArray) {
        if (idKay === name) {
          localStorage.setItem(`email${name}`, item.details.email)
          localStorage.setItem(`password${name}`, item.details.password)
        }
        if (idKay != "phoneUser") {
          myListData.push(
            {
              name: idKay,
              phone: item.details.phone,
              email: item.details.email,
              password: item.details.password,
              link: item.details.link,
              timeService: item.details.timeService,
              dateStartService: item.details.dateStartService,
              userWork: item.userWork
            }
          )
        }
      }
      localStorage.setItem('myArrayMenger', JSON.stringify(myListData));
    }
  };




  return (

    <div className='bodyl' >
      {
        <div className=' anBack'>
          <div className=" bg-white d-flex  justify-content-between ">
            <div className='position-relative  ps-2 mt-2'>
              <img
                style={{ transform: 'scaleX(-1)' }}
                className='animated-button '
                src={`${process.env.PUBLIC_URL}/imges/namelogot.png`}
                alt="Name Logo"
                height={40}
              />
            </div>
            <div className=" pe-2 animated-b ">
              <button onClick={nextPageLog} style={{ fontWeight: 'bolder' }} className='btn-login p-1 mb-1  mt-2  '>
                <FiLogIn size={22} />
                {/* style={{ transform: 'scaleX(-1)' }} */}
              </button>
            </div >
          </div>
          <div className=' d-lg-flex justify-content-lg-center'>
            <div className='position-relative col-lg-8  p-3 mt-3'>
              <div className='backimageBox shadow'>
                {/* <img
                  className='animated-button backBlue p-3'
                  src={`${process.env.PUBLIC_URL}/imges/backgoundblue.png`}
                  alt="Name Logo"
                /> */}
                <div className=''>
                  <div className=' d-flex justify-content-center  an'>
                    <div className='sizewithe text-center'>
                      <p className=' text-white text-center textt mt-4'>
                        אפליקצית שירותי חישוב של החזרי מס
                        ניהול לקוחות, חישוב החזר כספי מס
                        תוצאה מדויקת בלחיצת כפתור
                      </p>
                    </div>
                  </div>
                  <div className=' d-flex justify-content-center  an '>
                    <p className=' text-white  text-center textt mt-3'>
                      <h1>
                        touchpay איתך בפיננסים.
                      </h1>
                    </p>
                  </div>
                  <div className="d-flex flex-column justify-content-center align-items-center h-25 mt-4  sh">
                    <div className=" mb-4">
                      <button
                        onClick={nextPageMas}
                        style={{ fontWeight: 'bolder', fontSize: '18px' }}
                        className=" btn-rashi p-2 animated-button ps-5 text-center pe-5 pt-3 pb-3 ">
                        <IoMdArrowBack className=' pe-2' size={32} />
                        לבדיקת החזר מס
                      </button>
                    </div>
                    <div className=''>
                      <button
                        onClick={nextPageShevah}
                        style={{ fontWeight: 'bolder', fontSize: '18px' }}
                        className="btn-rashi p-2 animated-button ps-4 text-center pe-4 pt-3 pb-3"
                      >
                        <IoMdArrowBack className=' pe-2' size={32} />
                        לבדיקת החזר מס שבח
                      </button>
                    </div>
                  </div>


                  {/* {
              showTimeMess && <div className="dialog-container" style={{ direction: 'rtl' }}>
                <div className="dialog-content m-3">
                  <button style={{ background: 'none', border: 'none', fontWeight: 'bold', fontSize: '20px' }} className=' d-flex text-danger' onClick={() => setshowTimeMess(false)}>X</button>
                  <h4 className='p-2' >ניתן לבצע בדיקה נוספת רק לאחר 5 דקות</h4>
                  <h5 className='p-2  text-center text-danger' >זמן הנותר הוא: {remainingSecond} </h5>
                </div>
              </div>
            } */}

                </div>
              </div>
            </div>

          </div>
          
          <div className="d-flex justify-content-center align-bottom align-items-center fixed-bottom p-3">
            <button
              onClick={() => callCompny()}
              style={{ fontWeight: 'bolder', fontSize: '18px'  }}
              className='pe-3 ps-3 btnCall border-3'
            >
              צור קשר
              {/* צור קשר <MdContactPhone className='mb-1 align-bottom' size={23} /> */}
            </button>
          </div>

        </div>
        // : <div className="charging-circle-container">
        //   <div className="charging-circle"></div>
        // </div>

      }

    </div>

  )
}




